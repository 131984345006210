import { useState } from "react";

import Asset from "./subcomponent/Asset";

import {
  ApproveButton,
  AssetContainer,
  Button,
  ButtonContainer,
  Container,
  NavigationBar,
  Overlay,
  ApprovePrompt,
  SelectedNum,
  State,
  StateContainer,
  StatsContainer,
} from "./StakeSection.styles";

import fallback from "../../../../images/fallback.jpeg";

type StakeSectionProps = {
  unstakedMetadataPlaceholder: number[];
  stakedMetadataPlaceholder: number[];
  unstakedMetadata: any[];
  stakedMetadata: any[];
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  stakingAllowed: boolean;
  unstakingAllowed: boolean;
  approved: boolean;
  approve: any;
  stake: any;
  unstake: any;
  type: "ziggy" | "pirate";
  stakedCount: number;
  unStakedCount: number;
};

const StakeSection = ({
  unstakedMetadataPlaceholder,
  stakedMetadataPlaceholder,
  unstakedMetadata,
  stakedMetadata,
  selected,
  setSelected,
  stakingAllowed,
  unstakingAllowed,
  approved,
  approve,
  stake,
  unstake,
  type,
  stakedCount,
  unStakedCount,
}: StakeSectionProps) => {
  const [currentState, setCurrentState] = useState<"staked" | "unstaked">(
    "unstaked"
  );
  const [loading, setLoading] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);

  return (
    <Container>
      <NavigationBar>
        <StateContainer>
          <State
            active={currentState === "unstaked"}
            onClick={() => {
              setCurrentState("unstaked");
              setSelected([]);
            }}
          >
            Idle {type === "ziggy" ? "Ziggys" : "Pirates"} ({unStakedCount})
          </State>
          <State
            active={currentState === "staked"}
            onClick={() => {
              setCurrentState("staked");
              setSelected([]);
            }}
          >
            Staked {type === "ziggy" ? "Ziggys" : "Pirates"} ({stakedCount})
          </State>
        </StateContainer>
        <ButtonContainer>
          <ApprovePrompt>
            {!approved && (
              <ApproveButton
                disabled={approvalLoading}
                onClick={async () => {
                  setApprovalLoading(true);
                  await approve();
                  setApprovalLoading(false);
                }}
              >
                {approvalLoading ? "APPROVING" : "APPROVE"}
              </ApproveButton>
            )}
            {approved && (
              <SelectedNum>
                {selected.length} {type === "ziggy" ? "Ziggys" : "Pirates"}{" "}
                selected
              </SelectedNum>
            )}
          </ApprovePrompt>
          <Button
            disabled={
              !approved ||
              loading ||
              (currentState === "staked" && !unstakingAllowed) ||
              (currentState === "unstaked" && !stakingAllowed) ||
              selected.length === 0
            }
            onClick={async () => {
              try {
                if (currentState === "staked") {
                  setLoading(true);
                  await unstake();
                  setCurrentState("unstaked");
                } else {
                  setLoading(true);
                  await stake();
                  setCurrentState("staked");
                }
                setSelected([]);
                setLoading(false);
              } catch (e) {
                setSelected([]);
                setLoading(false);
              }
            }}
          >
            {currentState === "staked"
              ? loading
                ? "Unstaking"
                : "Unstake"
              : loading
              ? "Staking"
              : "Stake"}{" "}
            ({selected.length})
          </Button>
        </ButtonContainer>
      </NavigationBar>
      <StatsContainer>
        You Have{" "}
        {currentState === "staked"
          ? `${stakedCount} Staked`
          : `${unStakedCount} Idle`}{" "}
        {type === "ziggy" ? "Ziggys " : "Pirates "}
        {type === "ziggy"
          ? `Generating ${
              currentState === "staked" ? stakedCount * 2 : unStakedCount
            } Eggs Per Day`
          : `${currentState === "staked" ? "On Raiding Missions" : ""}`}
      </StatsContainer>
      <AssetContainer>
        {!approved && (
          <Overlay>
            <ApproveButton
              disabled={approvalLoading}
              onClick={async () => {
                setApprovalLoading(true);
                await approve();
                setApprovalLoading(false);
              }}
            >
              {approvalLoading ? "APPROVING" : "APPROVE"}
            </ApproveButton>
          </Overlay>
        )}
        {currentState === "staked" &&
          stakedMetadataPlaceholder.length &&
          !stakedMetadata.length &&
          stakedMetadataPlaceholder.map((data, i) => (
            <Asset
              name={type === "ziggy" ? `Ziggy #${data}` : `Pirate ${data}`}
              src={fallback}
              id={data}
              staked={true}
              selected={selected}
              setSelected={setSelected}
              key={type === "ziggy" ? `Ziggy #${data}` : `Pirate ${data}`}
              type={type === "ziggy" ? "Genesis" : "Pirate"}
            />
          ))}
        {currentState === "staked" &&
          stakedMetadata.map((data, i) => (
            <Asset
              name={data.name || "#???"}
              src={data.image || fallback}
              id={data.id || -1}
              staked={true}
              selected={selected}
              setSelected={setSelected}
              key={data.id}
              type={type === "ziggy" ? "Genesis" : `${data.attributes[0].value}`}
            />
          ))}
        {currentState === "unstaked" &&
          unstakedMetadataPlaceholder.length &&
          !unstakedMetadata.length &&
          unstakedMetadataPlaceholder.map((data, i) => (
            <Asset
              name={type === "ziggy" ? `Ziggy #${data}` : `Pirate ${data}`}
              src={fallback}
              id={data}
              staked={false}
              selected={selected}
              setSelected={setSelected}
              key={type === "ziggy" ? `Ziggy #${data}` : `Pirate ${data}`}
              type={type === "ziggy" ? "Genesis" : "Pirate"}
            />
          ))}
        {currentState === "unstaked" &&
          unstakedMetadata.map((data, i) => (
            <Asset
              name={data.name || "#???"}
              src={data.image || fallback}
              id={data.id}
              staked={false}
              selected={selected}
              setSelected={setSelected}
              key={data.id}
              type={type === "ziggy" ? "Genesis" : `${data.attributes[0].value}`}
            />
          ))}
      </AssetContainer>
    </Container>
  );
};

export default StakeSection;
