import { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "axios";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import {
  Card,
  CardContainer,
  CardTitle,
  ClaimButton,
  Container,
  Content,
  ContentImage,
  ContentText,
  Egg,
  EggContainer,
  EggCount,
  InfoHeading,
  Line,
  SmallTitle,
  TextContainer,
} from "./ClaimSection.styles";
import egg from "../../../../images/egg.png";
import ziggy from "../../../../images/ziggyClaim.png";

import ziggyAbi from "../../../../contracts/ziggyAbi.json";
import eggAbi from "../../../../contracts/eggAbi.json";
import distributorAbi from "../../../../contracts/distributorAbi.json";
import ziggyStakeAbi from "../../../../contracts/ziggyStakeAbi.json";

import {
  ZIGGY_ADDRESS,
  EGG_ADDRESS,
  DISTRIBUTOR_ADDRESS,
  CLAIM_ENDPOINT,
  ZIGGY_STAKING_ADDRESS,
} from "../../../../constants";

type ClaimSectionProps = {
  eggCount: number;
  setEggCount: Dispatch<SetStateAction<number>>;
  claimedAmount: number;
  setClaimedAmount: Dispatch<SetStateAction<number>>;
  ziggyCount: number;
  setZiggyCount: Dispatch<SetStateAction<number>>;
  unDistributedAmount: string;
  setUnDistributedAmount: Dispatch<SetStateAction<string>>;
  claimableAmount: string;
  setClaimableAmount: Dispatch<SetStateAction<string>>;
  claimCount: number;
  setClaimCount: Dispatch<SetStateAction<number>>;
  sig: string;
  setSig: Dispatch<SetStateAction<string>>;
  stakedZiggyCount: number;
  setStakedZiggyCount: Dispatch<SetStateAction<number>>;
};

const ClaimSection = ({
  eggCount,
  setEggCount,
  claimedAmount,
  setClaimedAmount,
  ziggyCount,
  setZiggyCount,
  unDistributedAmount,
  setUnDistributedAmount,
  claimableAmount,
  setClaimableAmount,
  claimCount,
  setClaimCount,
  sig,
  setSig,
  stakedZiggyCount,
  setStakedZiggyCount,
}: ClaimSectionProps) => {
  const [loading, setLoading] = useState(false);

  const { account, library } = useWeb3React<Web3Provider>();

  useEffect(() => {
    if (library && account) {
      // initialize ziggyContract
      const ziggyContract = new ethers.Contract(
        ZIGGY_ADDRESS,
        ziggyAbi,
        library?.getSigner()
      );

      const eggContract = new ethers.Contract(
        EGG_ADDRESS,
        eggAbi,
        library.getSigner()
      );

      const distributorContract = new ethers.Contract(
        DISTRIBUTOR_ADDRESS,
        distributorAbi,
        library.getSigner()
      );

      const ziggyStakeContract = new ethers.Contract(
        ZIGGY_STAKING_ADDRESS,
        ziggyStakeAbi,
        library.getSigner()
      );

      updateSupplies(
        eggContract,
        distributorContract,
        ziggyContract,
        ziggyStakeContract
      );

      const // poll every 1 minute for claimable amount
        interval = setInterval(() => {
          updateSupplies(
            eggContract,
            distributorContract,
            ziggyContract,
            ziggyStakeContract
          );
        }, 60000);

      // set up listener for claimed eggs (TODO: test if "to" address actually works)
      eggContract.on("Transfer", (from: string, to: string) => {
        if (from === DISTRIBUTOR_ADDRESS) {
          // update undistributed egg amount
          eggContract
            .balanceOf(EGG_ADDRESS)
            .then((res: ethers.BigNumber) => {
              setUnDistributedAmount(
                parseInt(ethers.utils.formatUnits(res, 18)).toString()
              );
            })
            .catch(console.error);
        }
        if (to === account) {
          // update undistributed egg amount
          eggContract
            .balanceOf(EGG_ADDRESS)
            .then((res: ethers.BigNumber) => {
              setUnDistributedAmount(
                parseInt(ethers.utils.formatUnits(res, 18)).toString()
              );
            })
            .catch(console.error);
        }
      });

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, library]);

  function updateSupplies(
    eggContract: ethers.Contract,
    distributorContract: ethers.Contract,
    ziggyContract: ethers.Contract,
    ziggyStakeContract: ethers.Contract
  ) {
    // find total undistributed egg supply
    eggContract
      .balanceOf(EGG_ADDRESS)
      .then((res: ethers.BigNumber) => {
        setUnDistributedAmount(
          parseInt(ethers.utils.formatUnits(res, 18)).toString()
        );
      })
      .catch(console.error);

    // get signature and total claimed amount
    axios
      .get(`${CLAIM_ENDPOINT}?wallet=${account}`, {
        withCredentials: false,
      })
      .then((res) => {
        const { data } = res;
        const { signature, amount } = data;

        // set claimable amount
        setClaimableAmount(amount);
        // set signature
        setSig(signature);

        distributorContract
          .claimedEggs(account)
          .then((claimedAmount: ethers.BigNumber) => {
            const claimed = parseFloat(
              ethers.utils.formatUnits(claimedAmount, 18)
            );
            setClaimedAmount(parseFloat(claimed.toFixed(3)));
            // calculate how much is left to be claimed
            const claimable = ethers.BigNumber.from(amount);
            const diff = claimable.sub(claimedAmount);
            const diffFloat = parseFloat(ethers.utils.formatUnits(diff, 18));
            setClaimCount(diffFloat > 0 ? parseFloat(diffFloat.toFixed(3)) : 0);
          })
          .catch(console.error);
      })
      .catch(console.error);

    // find ziggys owned by address
    ziggyContract
      .walletOfOwner(account)
      .then((owned: any[]) => {
        setZiggyCount(owned.length);
      })
      .catch(console.error);
    // and the staked ziggy ids
    ziggyStakeContract
      .balanceOf(account)
      .then(async (res: ethers.BigNumber) => {
        setStakedZiggyCount(parseInt(res.toString()));
      })
      .catch(console.error);

    // calculate balance of egg token of current address.
    // find how many eggs this account has
    eggContract
      .balanceOf(account)
      .then((res: ethers.BigNumber) => {
        const currentEggAmount = parseFloat(ethers.utils.formatUnits(res, 18));
        setEggCount(parseFloat(currentEggAmount.toFixed(3)));
      })
      .catch(console.error);
  }

  const claimEgg = async () => {
    if (account && library) {
      try {
        const contract = new ethers.Contract(
          DISTRIBUTOR_ADDRESS,
          distributorAbi,
          library.getSigner()
        );

        const eggContract = new ethers.Contract(
          EGG_ADDRESS,
          eggAbi,
          library.getSigner()
        );

        const tx = await contract.claim(claimableAmount, sig);

        setLoading(true);
        await tx.wait();
        setLoading(false);

        setClaimCount(0);
        // update current amount
        eggContract
          .balanceOf(account)
          .then((res: ethers.BigNumber) => {
            const currentEggAmount = parseFloat(
              ethers.utils.formatUnits(res, 18)
            );
            setEggCount(parseFloat(currentEggAmount.toFixed(3)));
          })
          .catch(console.error);
      } catch (e: any) {
        // @ts-ignore
        alert(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
      }
    }
  };

  return (
    <Container>
      <EggContainer>
        <Egg src={egg} />
        <TextContainer>
          <SmallTitle>You can claim:</SmallTitle>
          <EggCount>{claimCount} Dino Eggs</EggCount>
          <ClaimButton onClick={() => claimEgg()} loading={loading}>
            {loading ? "Claiming..." : "Claim your dinoeggs"}
          </ClaimButton>
        </TextContainer>
      </EggContainer>
      <InfoHeading>
        So far you have claimed {claimedAmount} Dino Eggs. There are{" "}
        {unDistributedAmount} eggs remaining in the distribution wallet.
      </InfoHeading>
      {/* <WarningHeading>
        WARNING! There are 1400 pirates raiding right now.
      </WarningHeading> */}
      <Line />
      <CardContainer>
        <Card>
          <CardTitle>
            How many Ziggys you <strong>have:</strong>
          </CardTitle>
          <Content>
            <ContentImage src={ziggy} />
            <ContentText>{ziggyCount + stakedZiggyCount} Ziggys</ContentText>
          </Content>
        </Card>
        <Card>
          <CardTitle>You have:</CardTitle>
          <Content>
            <ContentImage src={egg} />
            <ContentText>{eggCount} Dino Eggs</ContentText>
          </Content>
        </Card>
        <Card>
          <CardTitle>
            Dino Eggs <strong>earned</strong> per day:
          </CardTitle>
          <Content>
            <ContentImage src={egg} />
            <ContentText>
              {ziggyCount + stakedZiggyCount * 2} Dino Eggs
            </ContentText>
          </Content>
        </Card>
      </CardContainer>
    </Container>
  );
};

export default ClaimSection;
