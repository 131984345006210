import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { AccordionButton, Container, Content, Title } from "./FaqModule.styles";

const FaqModule = ({ content, title }: any) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <>
      <Container onClick={() => setIsOpened(!isOpened)}>
        <Title>{title}</Title>
        <AccordionButton opened={isOpened}>
          <FaChevronDown size={24} />
        </AccordionButton>
      </Container>
      {isOpened && <Content>{content}</Content>}
    </>
  );
};

export default FaqModule;
