import { Container, TeamContainer, Title } from "./Team.styles";
import TeamMember from "./subcomponents/TeamMember/TeamMember";

import alabasterDino from "../../images/alabasterDino.svg";
import kmiloDino from "../../images/kmiloDino.svg";
import mattDino from "../../images/mattDino.svg";
import snowDino from "../../images/Snow.png";
import darinDino from "../../images/darinDino.svg";
import djDino from "../../images/djDino.svg";

const members = [
  {
    imgSrc: mattDino,
    name: "Matt Bolinger",
    slug: "Creator / Lead Artist",
    twitter: "https://twitter.com/DinoBabyMatt",
    discord: "https://discordapp.com/users/640001950770921473/",
  },
  {
    imgSrc: alabasterDino,
    name: "Alabaster Jefferson",
    slug: "NFT Strategy / Project Mgt.",
    twitter: "https://twitter.com/AJFromDiscord",
    discord: "https://discordapp.com/users/134152981925986304/",
  },
  {
    imgSrc: kmiloDino,
    name: "Kmilo",
    slug: "Operations / Partnerships",
    twitter: "https://twitter.com/Kmilo_LA",
    discord: "https://discordapp.com/users/385605832127152138/",
  },
  {
    imgSrc: snowDino,
    name: "SnowBlossom",
    slug: "Community Manager",
    twitter: "https://twitter.com/CylixandCo",
    discord: "https://discordapp.com/users/307937245124624384/",
  },
  {
    imgSrc: darinDino,
    name: "Darin",
    slug: "Discord Manager",
    twitter: "https://twitter.com/darin_so",
    discord: "https://discordapp.com/users/185954567823818752/",
  },
  {
    imgSrc: djDino,
    name: "DJ",
    slug: "Engineering",
    twitter: "https://twitter.com/dinobabiesNFT",
    discord: "https://discordapp.com/users/426955233214857228/",
  },
];

const Team = () => {
  return (
    <Container id="team">
      <Title>THE TEAM</Title>
      <TeamContainer>
        {members.map((member) => (
          <TeamMember
            name={member.name}
            slug={member.slug}
            imgSrc={member.imgSrc}
            twitter={member.twitter}
            discord={member.discord}
            key={member.slug}
          />
        ))}
      </TeamContainer>
    </Container>
  );
};

export default Team;
