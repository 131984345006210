import styled from "styled-components";
import breakPoints from "../../../../constants/breakpoints";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 2rem;

  @media ${breakPoints.phoneOnly} {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  margin-left: 2rem;
  @media ${breakPoints.phoneOnly} {
    margin-left: 0;
  }
`;

export const SmallTitle = styled.h3`
  font-family: "Jura-Light";
  font-size: 1.375rem;
  margin: 1rem 0;

  @media ${breakPoints.phoneOnly} {
    margin: 0.5rem 0;
    font-size: 1.25rem;
  }
`;

export const EggCount = styled.div`
  font-size: 2.25rem;
  font-family: "Roboto-Bold";
`;

type ClaimButtonProps = {
  loading: boolean;
};

export const ClaimButton = styled.div<ClaimButtonProps>`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #ff488f;
  border-radius: 0.5rem;
  font-size: 1.125rem;
  padding: 0.75rem 1.25rem;
  font-family: "Jura-Medium";
  transition: 0.3s;
  cursor: pointer;

  opacity: ${(props) => (props.loading ? "0.2" : "1")};
  pointer-events: ${(props) => (props.loading ? "none" : "default")};

  &:hover {
    background-color: #ff488f;
    color: white;
  }

  @media ${breakPoints.phoneOnly} {
    margin-top: 0.5rem;
  }
`;

export const Line = styled.div`
  position: relative;
  width: 20rem;
  height: 1px;
  margin: 0 2rem;
  background-color: #474747;
  color: black;
`;

export const InfoHeading = styled.div`
  font-size: 0.875rem;
  font-family: "Roboto-Regular";
  margin: 2rem 0;

  @media ${breakPoints.phoneOnly} {
    text-align: center;
    width: 65%;
  }
`;

export const WarningHeading = styled.div`
  font-size: 0.875rem;
  font-family: "Roboto-Regular";
  margin: 0 0 2rem 0;
  color: red;

  @media ${breakPoints.phoneOnly} {
    text-align: center;
    width: 65%;
  }
`;

export const EggContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media ${breakPoints.phoneOnly} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const Egg = styled.img`
  width: 7.5rem;
  height: auto;

  @media ${breakPoints.phoneOnly} {
    width: 5rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  width: 80%;
`;

export const Card = styled.div`
  width: 18rem;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  -webkit-box-shadow: 4px 4px 15px 2px rgba(11, 130, 113, 0.34);
  box-shadow: 4px 4px 15px 2px rgba(11, 130, 113, 0.67);
  margin: 1rem 0;
`;

export const CardTitle = styled.p`
  font-family: "Roboto-Light";
  font-size: 1.125rem;
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 1rem;
`;

export const ContentImage = styled.img`
  height: 3rem;
  border-radius: 50%;
`;

export const ContentText = styled.p`
  font-family: "Roboto-Regular";
  font-size: 2.25rem;
  margin: 0 0 0 1rem;
`;
