import { FaDiscord, FaTwitter } from "react-icons/fa";
import {
  Container,
  ImageContainer,
  MemberImage,
  MemberName,
  MemberSlug,
  SocialLink,
  SocialLinks,
  TextContainer,
  ContentContainer,
} from "./TeamMember.styles";

const TeamMember = ({ imgSrc, name, slug, twitter, discord }: any) => {
  return (
    <Container>
      <ImageContainer>
        <MemberImage src={imgSrc} />
      </ImageContainer>
      <ContentContainer>
        <TextContainer>
          <MemberName>{name}</MemberName>
          <MemberSlug>{slug}</MemberSlug>
        </TextContainer>
        <SocialLinks>
          <SocialLink>
            <FaTwitter
              size={24}
              onClick={() => window.open(twitter, "_blank")}
            />
          </SocialLink>
          <SocialLink>
            <FaDiscord
              size={24}
              onClick={() => window.open(discord, "_blank")}
            />
          </SocialLink>
        </SocialLinks>
      </ContentContainer>
    </Container>
  );
};

export default TeamMember;
