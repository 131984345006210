import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";

import {
  Container,
  ContentContainer,
  SelectorContainer,
  Selector,
} from "./StakePage.styles";

import abi from "../../contracts/pirateAbi.json";
import ziggyAbi from "../../contracts/ziggyAbi.json";
import pirateStakeAbi from "../../contracts/pirateStakeAbi.json";
import ziggyStakeAbi from "../../contracts/ziggyStakeAbi.json";
import ClaimSection from "./subcomponents/ClaimSection/ClaimSection";
import StakeSection from "./subcomponents/StakeSection/StakeSection";

import {
  PIRATE_ADDRESS,
  PIRATE_STAKING_ADDRESS,
  ZIGGY_ADDRESS,
  ZIGGY_STAKING_ADDRESS,
  HOLDINGS_ENDPOINT,
} from "../../constants";
import GameExplainerSection from "./subcomponents/GameExplainerSection/GameExplainerSection";
import HistorySection from "./subcomponents/HistorySection/HistorySection";

const StakePage = () => {
  // page type
  const [type, setType] = useState<
    "stakeZiggy" | "stakePirate" | "claim" | "history" | "explainer"
  >("claim");

  // claim states
  const [eggCount, setEggCount] = useState(0);
  const [ziggyCount, setZiggyCount] = useState(0);
  const [unDistributedAmount, setUnDistributedAmount] = useState("3000000");
  const [claimableAmount, setClaimableAmount] = useState("0");
  const [claimCount, setClaimCount] = useState(0);
  const [claimedAmount, setClaimedAmount] = useState(0);
  const [sig, setSig] = useState("");

  // ziggy states
  const [ownedZiggyMetadata, setOwnedZiggyMetadata] = useState<any[]>([]);
  const [stakedZiggyCount, setStakedZiggyCount] = useState(0);
  const [stakedZiggyMetadata, setStakedZiggyMetadata] = useState<any[]>([]);
  const [selectedZiggys, setSelectedZiggys] = useState<number[]>([]);
  const [ziggyApprovalStatus, setZiggyApprovalStatus] = useState(false);
  const [ziggyStakingAllowed, setZiggyStakingAllowed] =
    useState<boolean>(false);
  const [ziggyUnstakingAllowed, setZiggyUnstakingAllowed] =
    useState<boolean>(false);
  const [ziggyBaseURI, setZiggyBaseURI] = useState(
    "https://ipfs.io/ipfs/QmNWj4fotwAL8EDmM1Ja3CPKJPAxjtYDKwhaPTB8a5Kr36/"
  );
  const [ziggyStakedMetadataPlaceholder, setZiggyStakedMetadataPlaceholder] =
    useState<number[]>([]);
  const [
    ziggyUnstakedMetadataPlaceholder,
    setZiggyUnstakedMetadataPlaceholder,
  ] = useState<number[]>([]);

  // pirate states
  const [ownedPirateMetadata, setOwnedPirateMetadata] = useState<any[]>([]);
  const [pirateCount, setPirateCount] = useState(0);
  const [stakedPirateCount, setStakedPirateCount] = useState(0);
  const [stakedPirateMetadata, setStakedPirateMetadata] = useState<any[]>([]);
  const [selectedPirates, setSelectedPirates] = useState<number[]>([]);
  const [pirateApprovalStatus, setPirateApprovalStatus] = useState(false);
  const [pirateStakingAllowed, setPirateStakingAllowed] =
    useState<boolean>(true);
  const [pirateUnstakingAllowed, setPirateUnstakingAllowed] =
    useState<boolean>(true);
  const [pirateBaseURI, setPirateBaseURI] = useState(
    "https://ipfs.io/ipfs/QmYgn9EWZkVy2kKmc9tgurCgts2GxMoBxyjXPaLT9TShcX/"
  );
  const [pirateStakedMetadataPlaceholder, setPirateStakedMetadataPlaceholder] =
    useState<number[]>([]);
  const [
    pirateUnstakedMetadataPlaceholder,
    setPirateUnstakedMetadataPlaceholder,
  ] = useState<number[]>([]);

  const [totalPirateStaked, setTotalPirateStaked] = useState(0);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { account, library } = useWeb3React<Web3Provider>();

  useEffect(() => {
    if (library && account) {
      // initialize contracts
      const pirateContract = new ethers.Contract(
        PIRATE_ADDRESS,
        abi,
        library?.getSigner()
      );
      const pirateStakeContract = new ethers.Contract(
        PIRATE_STAKING_ADDRESS,
        pirateStakeAbi,
        library.getSigner()
      );
      const ziggyContract = new ethers.Contract(
        ZIGGY_ADDRESS,
        ziggyAbi,
        library?.getSigner()
      );
      const ziggyStakeContract = new ethers.Contract(
        ZIGGY_STAKING_ADDRESS,
        ziggyStakeAbi,
        library.getSigner()
      );

      // get current approval status for ziggys and pirate
      ziggyContract
        .isApprovedForAll(account, ZIGGY_STAKING_ADDRESS)
        .then((res: boolean) => setZiggyApprovalStatus(res))
        .catch(console.error);

      pirateContract
        .isApprovedForAll(account, PIRATE_STAKING_ADDRESS)
        .then((res: boolean) => setPirateApprovalStatus(res))
        .catch(console.error);

      pirateContract.balanceOf(account).then((res: ethers.BigNumber) => {
        setPirateCount(res.toNumber());
      });
      ziggyContract.balanceOf(account).then((res: ethers.BigNumber) => {
        setZiggyCount(res.toNumber());
      });
      updatePirateStakeNumbers(pirateStakeContract, pirateContract);
      updateZiggyStakeNumbers(ziggyStakeContract, ziggyContract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, library]);

  const updatePirateStakeNumbers = (
    pirateStakeContract: ethers.Contract,
    pirateContract: ethers.Contract
  ) => {
    try {
      pirateContract.balanceOf(account).then((res: ethers.BigNumber) => {
        setPirateCount(res.toNumber());
        if (res.toNumber() > 50) {
          axios
            .get(`${HOLDINGS_ENDPOINT}?wallet=${account}&type=pirate`, {
              withCredentials: false,
            })
            .then(async (res) => {
              let metadataList = [];
              const walletOfOwner = res.data;
              setPirateUnstakedMetadataPlaceholder(
                walletOfOwner.map((t: any) => parseInt(t.toString()))
              );
              for (let i = 0; i < walletOfOwner.length; i++) {
                const id = parseInt(walletOfOwner[i]);
                try {
                  const result = await axios.get(pirateBaseURI + id);
                  const metadata = result.data;
                  metadataList.push({ ...metadata, id });
                } catch (e) {
                  console.error(e);
                }
              }
              setOwnedPirateMetadata(metadataList);
            });
        } else {
          pirateContract
            .walletOfOwner(account)
            .then(async (res: ethers.BigNumber[]) => {
              setPirateCount(res.length);
              setPirateUnstakedMetadataPlaceholder(
                res.map((t) => t.toNumber())
              );

              let metadataList = [];
              for (let i = 0; i < res.length; i++) {
                const id = parseInt(res[i].toString());
                // doing this instead of tokenURI to save some bandwith
                try {
                  const result = await axios.get(pirateBaseURI + id);
                  const metadata = result.data;
                  metadataList.push({ ...metadata, id });
                } catch (e) {
                  console.error(e);
                }
              }
              setOwnedPirateMetadata(metadataList);
            })
            .catch(console.error);
        }
      });

      // get the staked pirate ids
      pirateStakeContract
        .stakeOf(account)
        .then(async (res: ethers.BigNumber[]) => {
          setStakedPirateCount(res.length);
          setPirateStakedMetadataPlaceholder(res.map((t) => t.toNumber()));
          let metadataList = [];
          for (let i = 0; i < res.length; i++) {
            const id = parseInt(res[i].toString());
            // doing this instead of tokenURI to save some bandwith
            try {
              const result = await axios.get(pirateBaseURI + id);
              const metadata = result.data;
              metadataList.push({ ...metadata, id });
            } catch (e) {
              console.error(e);
            }
          }
          setStakedPirateMetadata(metadataList);
        })
        .catch(console.error);
      pirateStakeContract
        .allowStaking()
        .then((res: boolean) => setPirateStakingAllowed(res))
        .catch(console.error);
      pirateStakeContract
        .allowUnstaking()
        .then((res: boolean) => setPirateUnstakingAllowed(res))
        .catch(console.error);
    } catch (e) {
      console.error(e);
    }
  };

  const updateZiggyStakeNumbers = (
    ziggyStakeContract: ethers.Contract,
    ziggyContract: ethers.Contract
  ) => {
    ziggyContract.balanceOf(account).then((res: ethers.BigNumber) => {
      setZiggyCount(res.toNumber());
      // get the owned / unstaked ziggy ids
      if (res.toNumber() > 50) {
        axios
          .get(`${HOLDINGS_ENDPOINT}?wallet=${account}&type=ziggy`, {
            withCredentials: false,
          })
          .then(async (res) => {
            let metadataList = [];
            const walletOfOwner = res.data;
            setZiggyUnstakedMetadataPlaceholder(
              walletOfOwner.map((t: any) => parseInt(t.toString()))
            );
            for (let i = 0; i < walletOfOwner.length; i++) {
              const id = parseInt(walletOfOwner[i]);
              try {
                const result = await axios.get(ziggyBaseURI + id);
                const metadata = result.data;
                metadataList.push({ ...metadata, id });
              } catch (e) {
                console.error(e);
              }
            }
            setOwnedZiggyMetadata(metadataList);
          });
      } else {
        ziggyContract
          .walletOfOwner(account)
          .then(async (res: ethers.BigNumber[]) => {
            setZiggyCount(res.length);
            let metadataList = [];
            setZiggyUnstakedMetadataPlaceholder(res.map((t) => t.toNumber()));
            for (let i = 0; i < res.length; i++) {
              const id = parseInt(res[i].toString());
              // doing this instead of tokenURI to save some bandwith
              try {
                const result = await axios.get(ziggyBaseURI + id);
                const metadata = result.data;
                metadataList.push({ ...metadata, id });
              } catch (e) {
                console.error(e);
              }
            }
            setOwnedZiggyMetadata(metadataList);
          })
          .catch(console.error);
      }
    });

    // get the staked ziggy ids
    ziggyStakeContract
      .stakeOf(account)
      .then(async (res: ethers.BigNumber[]) => {
        setStakedZiggyCount(res.length);
        setZiggyStakedMetadataPlaceholder(res.map((t) => t.toNumber()));
        let metadataList = [];
        for (let i = 0; i < res.length; i++) {
          const id = parseInt(res[i].toString());
          // doing this instead of tokenURI to save some bandwith
          try {
            const result = await axios.get(ziggyBaseURI + id);
            const metadata = result.data;
            metadataList.push({ ...metadata, id });
          } catch (e) {
            console.error(e);
          }
        }
        setStakedZiggyMetadata(metadataList);
      })
      .catch(console.error);
    ziggyStakeContract
      .allowStaking()
      .then((res: boolean) => setZiggyStakingAllowed(res))
      .catch(console.error);
    ziggyStakeContract
      .allowUnstaking()
      .then((res: boolean) => setZiggyUnstakingAllowed(res))
      .catch(console.error);
  };

  const setApproval = async (
    stakingAddress: string,
    contractAddress: string,
    abi: any
  ) => {
    if (account && library) {
      try {
        const contract = new ethers.Contract(
          contractAddress,
          abi,
          library.getSigner()
        );
        const tx = await contract.setApprovalForAll(stakingAddress, true);

        setSuccess(false);
        setLoading(true);
        await tx.wait();
        if (stakingAddress === ZIGGY_STAKING_ADDRESS) {
          setZiggyApprovalStatus(true);
        } else {
          setPirateApprovalStatus(true);
        }
        setLoading(false);
        setSuccess(true);
      } catch (e: any) {
        // @ts-ignore
        alert(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
        setLoading(false);
        setError(true);
        // @ts-ignore
        setErrorMsg(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
      }
    }
  };

  const stake = async (
    stakingAddress: string,
    stakeAbi: any,
    tokens: number[]
  ) => {
    if (account && library) {
      try {
        const stakeContract = new ethers.Contract(
          stakingAddress,
          stakeAbi,
          library.getSigner()
        );

        const pirateContract = new ethers.Contract(
          PIRATE_ADDRESS,
          abi,
          library?.getSigner()
        );

        const ziggyContract = new ethers.Contract(
          ZIGGY_ADDRESS,
          ziggyAbi,
          library?.getSigner()
        );

        const tx = await stakeContract.stake(tokens);

        setSuccess(false);
        setLoading(true);
        await tx.wait();
        setLoading(false);
        setSuccess(true);
        // Update numbers
        if (stakingAddress === PIRATE_STAKING_ADDRESS) {
          updatePirateStakeNumbers(stakeContract, pirateContract);
        } else {
          updateZiggyStakeNumbers(stakeContract, ziggyContract);
        }
      } catch (e: any) {
        // @ts-ignore
        alert(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
        setLoading(false);
        setError(true);
        // @ts-ignore
        setErrorMsg(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
      }
    }
  };

  const unstake = async (
    stakingAddress: string,
    stakeAbi: any,
    tokens: number[]
  ) => {
    if (account && library) {
      try {
        const stakeContract = new ethers.Contract(
          stakingAddress,
          stakeAbi,
          library.getSigner()
        );

        const ziggyContract = new ethers.Contract(
          ZIGGY_ADDRESS,
          ziggyAbi,
          library?.getSigner()
        );

        const pirateContract = new ethers.Contract(
          PIRATE_ADDRESS,
          abi,
          library?.getSigner()
        );

        const tx = await stakeContract.unstake(tokens);

        setSuccess(false);
        setLoading(true);
        await tx.wait();
        setLoading(false);
        setSuccess(true);
        // Update Numbers
        if (stakingAddress === PIRATE_STAKING_ADDRESS) {
          updatePirateStakeNumbers(stakeContract, pirateContract);
        } else {
          updateZiggyStakeNumbers(stakeContract, ziggyContract);
        }
      } catch (e: any) {
        // @ts-ignore
        alert(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
        setLoading(false);
        setError(true);
        // @ts-ignore
        setErrorMsg(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
      }
    }
  };

  const renderView = () => {
    switch (type) {
      case "stakeZiggy":
        return (
          <StakeSection
            stakedMetadataPlaceholder={ziggyStakedMetadataPlaceholder}
            unstakedMetadataPlaceholder={ziggyUnstakedMetadataPlaceholder}
            stakedMetadata={stakedZiggyMetadata}
            unstakedMetadata={ownedZiggyMetadata}
            selected={selectedZiggys}
            setSelected={setSelectedZiggys}
            stakingAllowed={ziggyStakingAllowed}
            unstakingAllowed={ziggyUnstakingAllowed}
            approved={ziggyApprovalStatus}
            approve={async () =>
              await setApproval(ZIGGY_STAKING_ADDRESS, ZIGGY_ADDRESS, ziggyAbi)
            }
            stake={async () =>
              await stake(ZIGGY_STAKING_ADDRESS, ziggyStakeAbi, selectedZiggys)
            }
            unstake={async () =>
              await unstake(
                ZIGGY_STAKING_ADDRESS,
                ziggyStakeAbi,
                selectedZiggys
              )
            }
            type="ziggy"
            stakedCount={stakedZiggyCount}
            unStakedCount={ziggyCount}
          />
        );
      case "stakePirate":
        return (
          <StakeSection
            stakedMetadataPlaceholder={pirateStakedMetadataPlaceholder}
            unstakedMetadataPlaceholder={pirateUnstakedMetadataPlaceholder}
            stakedMetadata={stakedPirateMetadata}
            unstakedMetadata={ownedPirateMetadata}
            selected={selectedPirates}
            setSelected={setSelectedPirates}
            stakingAllowed={pirateStakingAllowed}
            unstakingAllowed={pirateUnstakingAllowed}
            approved={pirateApprovalStatus}
            approve={async () =>
              await setApproval(PIRATE_STAKING_ADDRESS, PIRATE_ADDRESS, abi)
            }
            stake={async () =>
              await stake(
                PIRATE_STAKING_ADDRESS,
                pirateStakeAbi,
                selectedPirates
              )
            }
            unstake={async () =>
              await unstake(
                PIRATE_STAKING_ADDRESS,
                pirateStakeAbi,
                selectedPirates
              )
            }
            type="pirate"
            stakedCount={stakedPirateCount}
            unStakedCount={pirateCount}
          />
        );
      case "claim":
        return (
          <ClaimSection
            eggCount={eggCount}
            setEggCount={setEggCount}
            claimedAmount={claimedAmount}
            setClaimedAmount={setClaimedAmount}
            ziggyCount={ziggyCount}
            setZiggyCount={setZiggyCount}
            unDistributedAmount={unDistributedAmount}
            setUnDistributedAmount={setUnDistributedAmount}
            claimableAmount={claimableAmount}
            setClaimableAmount={setClaimableAmount}
            claimCount={claimCount}
            setClaimCount={setClaimCount}
            sig={sig}
            setSig={setSig}
            stakedZiggyCount={stakedZiggyCount}
            setStakedZiggyCount={setStakedZiggyCount}
          />
        );
      case "history":
        return <HistorySection />;
      case "explainer":
        return <GameExplainerSection />;
      default:
        break;
    }
  };

  return (
    <Container>
      <SelectorContainer>
        <Selector
          active={type === "stakeZiggy"}
          onClick={() => {
            setType("stakeZiggy");
            setSelectedZiggys([]);
          }}
        >
          Ziggy Actions
        </Selector>
        <Selector
          active={type === "stakePirate"}
          onClick={() => {
            setType("stakePirate");
            setSelectedPirates([]);
          }}
        >
          Pirate Actions
        </Selector>
        <Selector active={type === "claim"} onClick={() => setType("claim")}>
          Eggs
        </Selector>
        <Selector
          active={type === "history"}
          onClick={() => setType("history")}
        >
          Egg History
        </Selector>
        <Selector
          active={type === "explainer"}
          onClick={() => setType("explainer")}
        >
          Game Explainer
        </Selector>
      </SelectorContainer>
      <ContentContainer>{renderView()}</ContentContainer>
    </Container>
  );
};

export default StakePage;
