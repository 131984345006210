import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(239, 140, 85, 0.61);
`;

export const ContentImage = styled.img`
  width: 15rem;
  height: 15rem;
  margin-right: 5rem;

  @media ${breakPoints.tabletPortraitUp} {
    width: 15rem;
    height: 15rem;
  }

  @media ${breakPoints.phoneOnly} {
    width: 10rem;
    height: 10rem;
    margin-right: 0;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 2rem;

  @media ${breakPoints.phoneOnly} {
    flex-direction: column;
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  max-width: 30rem;

  @media ${breakPoints.tabletPortraitUp} {
    margin-left: 2rem;
  }

  @media ${breakPoints.phoneOnly} {
    margin-left: 0;
  }
`;

export const Paragraph = styled.p`
  font-family: "Roboto-Regular";
  margin: 0.5rem 0;
`;

export const InfoSection = styled.p`
  font-size: 1.5rem;
  font-family: "Jura-Medium";

  @media ${breakPoints.phoneOnly} {
    font-size: 1rem;
  }
`;

export const MintButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${breakPoints.phoneOnly} {
    justify-content: space-evenly;
  }
`;

export const MintButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 7.8125rem;
  height: 5.125rem;
  border-radius: 0.5rem;
  border: 2px transparent solid;
  font-size: 2.25rem;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const Minus = styled.div`
  margin: 0 0 0 0.5rem;
  padding: 0 0.5rem;
  border-right: 1px #e5e5e5 solid;
  border-radius: 0.5rem 0 0 0.5rem;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  &:hover {
    background-color: rgb(255, 72, 143, 0.29);
  }
`;

export const Plus = styled.div`
  margin: 0 0.5rem 0 0;
  padding: 0 0.5rem;
  border-left: 1px #e5e5e5 solid;
  border-radius: 0 0.5rem 0.5rem 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  &:hover {
    background-color: rgb(255, 72, 143, 0.29);
  }
`;

export const Num = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
`;

export const OpenseaButton = styled.a`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  border: 2px solid #ff488f;
  color: black;
  font-size: 1.25rem;
  font-family: "Jura-Medium";
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  transition: 0.2s;
  margin-top: 2rem;

  @media ${breakPoints.phoneOnly} {
    margin: 2rem auto;
  }
`;

type ButtonProps = {
  disabled: boolean;
};

export const Button = styled.div<ButtonProps>`
  display: flex;
  margin-left: 1rem;
  align-items: center;
  justify-content: center;
  width: 12.25rem;
  height: 5.125rem;
  border-radius: 0.5rem;
  border: 2px solid #ff488f;
  background-color: black;
  color: white;
  font-size: 2.25rem;
  font-family: "Jura-Medium";
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }

  ${(props) => (props.disabled ? "pointer-events: none; opacity: 0.3;" : "")}
  ${(props) => (props.hidden ? "display: none" : "")}
`;

export const Title = styled.h1``;

export const MintTypeSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40rem;
  font-family: "Jura-Medium";
  margin-top: 2rem;

  @media ${breakPoints.phoneOnly} {
    width: 20rem;
  }
`;

type MintTypeSelectorProps = {
  active: boolean;
};

export const MintTypeSelector = styled.div<MintTypeSelectorProps>`
  width: 50%;
  text-align: center;
  border-bottom: 1px solid black;
  padding: 2rem 0;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#DF956B" : "transparent")};
`;
