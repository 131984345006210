import {
  ArtGuy,
  Container,
  ContentText,
  RoadMapContainer,
  Title,
} from "./Roadmap.styles";
import Season from "./subcomponents/Season/Season";

import artGuy from "../../images/art-guy.png";

const seasons = [
  {
    title: "Season 1: The Dino, The Eggs and the Community Wallet",
    content: (
      <>
        <ContentText>
          Genesis mint of the Dino Babies launches with 5,500 on-chain generated
          Ziggys.
        </ContentText>
        <br />
        <ContentText>
          After genesis mint, the Dino Babies in Space community wallet gets
          funded and 3,000,000 $DinoEggs, the governance token are created.
        </ContentText>
        <br />
        <ContentText>
          Ziggys start to generate $DinoEggs passively after the creation of the
          Community Wallet
        </ContentText>
        <br />
        <ContentText>
          The production team starts work on the Animated Series Pilot
        </ContentText>
      </>
    ),
  },
  {
    title: "Season 2: Raiders of the Lost Planet",
    content: (
      <>
        <ContentText>
          Series 2 mint of 8,888 uniquely generated Space Pirates
        </ContentText>
        <br />
        <ContentText>
          Two different minting methods: Mint with $DinoEggs or ETH
        </ContentText>
        <br />
        <ContentText>Launch of the Play-to-earn idle game</ContentText>
        <br />
        <ContentText>Staking of Ziggy and Pirates unlocked</ContentText>
        <br />
      </>
    ),
  },
  {
    title: "Season 3: See You Space Baby",
    content: (
      <>
        <ContentText>Project Miranda launches with 10,000 NFTs</ContentText>
        <br />
        <ContentText>Dino Babies enters the 3d Metaverse</ContentText>
        <br />
        <ContentText>Dino Babies Artist Grant Program launches</ContentText>
        <br />
        <ContentText>Dino Babies merch store opens with plushies</ContentText>
        <br />
        <ContentText>
          Merch can be bought with eth, $usd and $DinoEggs
        </ContentText>
      </>
    ),
  },
  {
    title: "Season 4: The Dino Who Sold the World",
    content: (
      <>
        <ContentText>
          NFT Project President launches - He’s all about the money
        </ContentText>
        <br />
        <ContentText>
          Dino Babies in Space children’s storybook series
        </ContentText>
        <br />
        <ContentText>Much more community focused funding</ContentText>
      </>
    ),
  },
];

const Roadmap = () => {
  return (
    <Container id="roadmap">
      <Title>ROADMAP</Title>
      <RoadMapContainer>
        {seasons.map((season, i) => (
          <Season
            title={season.title}
            content={season.content}
            num={i + 1}
            complete={i === 0}
            key={season.title}
          />
        ))}
      </RoadMapContainer>
      <ArtGuy src={artGuy} />
    </Container>
  );
};

export default Roadmap;
