import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  width: 100%;
  height: 13.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  background: black;
  color: white;
`;

export const MiddleStrip = styled.div`
  position: relative;
  width: 100%;
  height: 2.75rem;
  background-color: #ff488f;
  margin-top: 1rem;
`;

export const ZiggyButt = styled.img`
  position: absolute;
  bottom: 0;
  right: 20%;
  width: 30rem;
  @media ${breakPoints.tabletPortraitUp} {
    width: 20rem;
    right: 5%;
  }
  @media ${breakPoints.phoneOnly} {
    width: 13rem;
    right: 5%;
  }
`;

export const LeftContainer = styled.div`
  margin-left: 2rem;
  @media ${breakPoints.phoneOnly} {
    margin-left: 1rem;
  }
`;

export const RightContainer = styled.div``;

export const BrandingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.div`
  font-family: "Jura-Medium";
  font-size: 2rem;
  margin: 0;
  @media ${breakPoints.phoneOnly} {
    font-size: 1.25rem;
  }
`;

export const Copyright = styled.div`
  text-align: left;
  margin-top: 0.5rem;
  @media ${breakPoints.phoneOnly} {
    font-size: 0.75rem;
  }
`;

export const OpenSea = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("images/opensea-logo.png");
  background-position: left center;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
  padding: 1rem 1rem 0 0;
  @media ${breakPoints.phoneOnly} {
    padding: 1rem 0.5rem 0 0;
  }
`;

export const SocialLink = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 1rem 0 0;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }
  @media ${breakPoints.phoneOnly} {
    padding: 0 0 0 1rem;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
