import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 109vw;
  background-size: 100% 100%;
  background-image: url("images/background.jpg");
  overflow-x: hidden;
  @media ${breakPoints.phoneOnly} {
    width: 100%;
    height: 142vw;
    background-image: url("images/background.jpg");
  }
`;

export const MintButton = styled.div`
  font-family: "Jura-Medium";
  position: absolute;
  top: 55vh;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  padding: 1rem 2rem;
  background-color: black;
  color: white;
  font-size: 2.25rem;
  border-radius: 0.5rem;
  z-index: 100;

  @media ${breakPoints.tabletPortraitUp} {
    top: 22vh;
  }

  @media ${breakPoints.phoneOnly} {
    display: none;
  }
`;

export const Hologram = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  width: 70rem;
  height: 40rem;
  background-size: 100% 100%;
  background-image: url("images/hologram.png");
  font-family: "Jura-Medium";

  @media ${breakPoints.tabletPortraitUp} {
    width: 42rem;
    height: 30rem;
    background-image: url("images/hologram-mobile.png");
  }

  @media ${breakPoints.phoneOnly} {
    width: 23.4rem;
    height: 22rem;
  }
`;

export const HologramContent = styled.div`
  width: 90%;
  height: 65%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media ${breakPoints.tabletPortraitUp} {
    flex-direction: column;
    height: 85%;
    width: 100%;
  }

  @media ${breakPoints.phoneOnly} {
    flex-direction: column;
    height: 85%;
    width: 90%;
  }
`;

export const HologramTextContainer = styled.div`
  padding: 2rem;

  @media ${breakPoints.tabletPortraitUp} {
    padding: 0 0.5rem;
  }

  @media ${breakPoints.phoneOnly} {
    padding: 0.5rem;
  }
`;

export const HologramTitle = styled.h1`
  font-size: 2rem;
  text-align: center;
  @media ${breakPoints.phoneOnly} {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
  }
`;

export const HologramSubtitle = styled.p`
  font-size: 1.125rem;
  margin: 0 3rem;
  @media ${breakPoints.phoneOnly} {
    font-size: 0.725rem;
    margin: 0 1rem;
  }
`;

export const PirateTakeoverBanner = styled.img`
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
`;
