import styled from "styled-components";
import breakPoints from "../../../../constants/breakpoints";

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2rem;
  background-color: black;
  color: white;
  min-height: 60vh;
  max-height: 60vh;
  border: 2px solid #d3d0c9;
  font-family: "Fairfax";

  overflow-y: scroll;
  overflow-x: hidden;

  @media ${breakPoints.phoneOnly} {
    flex-direction: column;
  }
`;

export const TopBanner = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 2rem;
  background: linear-gradient(90deg, #0c2666 0%, #aac8ea 100%);
  z-index: 10;
  border-bottom: 2px solid #d3d0c9;
`;

export const IntroText = styled.p`
  margin-top: 1rem;
  padding: 0 0.5rem;
`;

export const InfoHeading = styled.div`
  padding: 0 0.5rem;
  font-size: 0.875rem;
  margin: 2rem 0;
`;

export const Data = styled.div`
  margin-bottom: 1rem;
`;
