import styled from "styled-components";
import breakPoints from "../../../../constants/breakpoints";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const NumContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 3rem;
  height: 100%;
`;

export const SeasonNum = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 50%;
  color: white;
  flex: 0 1 auto;
  @media ${breakPoints.phoneOnly} {
    display: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  height: auto;

  @media ${breakPoints.phoneOnly} {
    padding-left: 1rem;
    margin-left: 0rem;
    border-left: 3px solid black;
    width: 85%;
  }
`;

export const SeasonTitle = styled.h3`
  font-size: 1.5rem;
  font-family: "Roboto-Bold";
  margin-top: 1.25rem;

  @media ${breakPoints.tabletPortraitUp} {
    font-size: 1.6rem;
    margin-top: 1rem;
  }
`;

type LineProps = {
  show: boolean;
  num: number;
};

export const Line = styled.div<LineProps>`
  width: 3px;
  height: ${(props) =>
    props.num === 3 || props.num === 2 ? "11.25rem" : "8rem"};
  margin: 0 auto;
  background-color: black;
  flex: 1 1 auto;
  flex-grow: 1;

  display: ${(props) => (props.show ? "flex" : "none")};

  @media ${breakPoints.tabletPortraitUp} {
    height: ${(props) =>
      props.num === 1
        ? "11rem"
        : props.num === 2
        ? "11rem"
        : props.num === 3
        ? "11rem"
        : "0rem"};
  }

  @media ${breakPoints.phoneOnly} {
    display: none;
  }
`;

export const PaintOver = styled.img`
  position: absolute;
  width: 80%;
  margin-left: 3rem;
  margin-top: -3rem;

  @media ${breakPoints.tabletPortraitUp} {
    width: 80%;
    height: 100%;
    margin-left: 0;
    margin-top: 0rem;
  }
`;
