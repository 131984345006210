import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  DISCORD_VERIFICATION_ENDPOINT,
  PIRATE_ADDRESS,
  PIRATE_STAKING_ADDRESS,
  ZIGGY_ADDRESS,
  ZIGGY_STAKING_ADDRESS,
} from "../../constants";
import {
  Button,
  Card,
  CardContainer,
  CardNum,
  CardTitle,
  Container,
  Description,
  Title,
} from "./Verify.styles";

import pirateAbi from "../../contracts/pirateAbi.json";
import ziggyAbi from "../../contracts/ziggyAbi.json";
import pirateStakeAbi from "../../contracts/pirateStakeAbi.json";
import ziggyStakeAbi from "../../contracts/ziggyStakeAbi.json";
function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Verify = () => {
  const { account, library } = useWeb3React<Web3Provider>();

  const query = useQuery();

  const [ziggyWallet, setZiggyWallet] = useState(0);
  const [ziggyStaked, setZiggyStaked] = useState(0);
  const [pirateWallet, setPirateWallet] = useState(0);
  const [pirateStaked, setPirateStaked] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      if (library && account) {
        // initialize contracts
        const pirateContract = new ethers.Contract(
          PIRATE_ADDRESS,
          pirateAbi,
          library?.getSigner()
        );
        const pirateStakeContract = new ethers.Contract(
          PIRATE_STAKING_ADDRESS,
          pirateStakeAbi,
          library.getSigner()
        );
        const ziggyContract = new ethers.Contract(
          ZIGGY_ADDRESS,
          ziggyAbi,
          library?.getSigner()
        );
        const ziggyStakeContract = new ethers.Contract(
          ZIGGY_STAKING_ADDRESS,
          ziggyStakeAbi,
          library.getSigner()
        );

        ziggyContract
          .balanceOf(account)
          .then((res: ethers.BigNumber) => {
            setZiggyWallet(res.toNumber());
          })
          .catch(console.error);

        pirateContract
          .balanceOf(account)
          .then((res: ethers.BigNumber) => {
            setPirateWallet(res.toNumber());
          })
          .catch(console.error);

        ziggyStakeContract
          .balanceOf(account)
          .then((res: ethers.BigNumber) => {
            setZiggyStaked(res.toNumber());
          })
          .catch(console.error);

        pirateStakeContract
          .balanceOf(account)
          .then((res: ethers.BigNumber) => {
            setPirateStaked(res.toNumber());
          })
          .catch(console.error);
      }
    } catch (e) {}
  }, [library, account]);

  const verify = async () => {
    if (library && account) {
      const signer = library.getSigner();

      setLoading(true);
      const signature = await signer.signMessage("Verify Discord Account");

      await axios.get(DISCORD_VERIFICATION_ENDPOINT, {
        params: {
          discordId: query.get("discordId"),
          walletId: account,
          ziggys: ziggyWallet || ziggyStaked,
          pirates: pirateWallet || pirateStaked,
          signature,
        },
      });
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Discord Verification</Title>
      <Description>
        Connect your wallet and verify your owned or staked ziggys/pirates by
        clicking the button below.
      </Description>
      <CardContainer>
        <Card>
          <CardTitle>Ziggys in wallet</CardTitle>
          <CardNum>{ziggyWallet}</CardNum>
        </Card>
        <Card>
          <CardTitle>Pirates in wallet</CardTitle>
          <CardNum>{pirateWallet}</CardNum>
        </Card>
        <Card>
          <CardTitle>Ziggys staked</CardTitle>
          <CardNum>{ziggyStaked}</CardNum>
        </Card>
        <Card>
          <CardTitle>Pirates staked</CardTitle>
          <CardNum>{pirateStaked}</CardNum>
        </Card>
      </CardContainer>
      <Button
        onClick={() => verify()}
        disabled={
          !(ziggyWallet || ziggyStaked || pirateWallet || pirateStaked) ||
          loading ||
          typeof account !== "string" ||
          library === undefined
        }
      >
        Verify
      </Button>
    </Container>
  );
};

export default Verify;
