import styled from "styled-components";
import breakPoints from "../../../../constants/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
`;

export const Title = styled.div`
  text-align: left;
  font-size: 4.5rem;
  font-family: "Jura-Medium";
  margin: 0.5rem;
  @media ${breakPoints.phoneOnly} {
    font-size: 2.25rem;
  }
`;

export const Description = styled.p`
  width: 75%;
  margin-bottom: 2rem;
  @media ${breakPoints.phoneOnly} {
    text-align: left;
  }
`;

export const FAQContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  border-radius: 3rem;
  border: 0.375rem black solid;
  padding: 2rem;
  background-color: #ffd0b5;

  @media ${breakPoints.phoneOnly} {
    border: none;
    width: 90%;
    padding: 0.5rem 0;
    background-color: transparent;
  }
`;

export const FaqText = styled.p`
  font-size: 1.125rem;
  margin: 0.125rem;

  @media ${breakPoints.phoneOnly} {
    font-size: 0.875rem;
    text-align: left;
  }
`;

export const Link = styled.a`
  font-size: 1.125rem;
  color: blue;
  cursor: pointer;

  @media ${breakPoints.phoneOnly} {
    font-size: 0.875rem;
  }
`;
