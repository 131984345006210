import {
  Container,
  ItemDescription,
  ItemDescriptionContainer,
  ItemImage,
  ItemName,
} from "./Asset.styles";

type AssetProps = {
  name: string;
  src: string;
  id: number;
  staked: boolean;
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  type: string;
};

const Asset = ({
  name,
  src,
  id,
  staked,
  selected,
  setSelected,
  type,
}: AssetProps) => {
  return (
    <Container
      selected={selected.includes(id)}
      onClick={() => {
        if (selected.includes(id)) {
          let index = selected.indexOf(id);
          let temp = [...selected];
          if (index !== -1) {
            temp.splice(index, 1);
          }
          setSelected(temp);
        } else {
          setSelected([...selected, id]);
        }
      }}
    >
      <ItemImage src={src} alt={name} loading="lazy" />
      <ItemDescriptionContainer>
        <ItemName>{name}</ItemName>
        <ItemDescription>
          Type: {type}
        </ItemDescription>
        <ItemDescription>Staked: {staked ? "Yes" : "No"}</ItemDescription>
      </ItemDescriptionContainer>
    </Container>
  );
};

export default Asset;
