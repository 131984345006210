import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .6);
  padding: 1rem;
  box-sizing: border-box;
`;

export const Content = styled.div`
  background-color: #fff;
  box-shadow: 14px 14px 0px rgba(116, 16, 55, 0.75);
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  padding: 2rem 1rem;

  @media ${breakPoints.tabletLandscapeUp} {
    padding: 3rem;
  }
`;

export const Button = styled.button`
  all: unset;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export const Image = styled.img``;

export const Message = styled.div`
  font-size: 1.4rem;
  display: block;
`;
