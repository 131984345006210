import {
  Container,
  Hologram,
  HologramContent,
  HologramSubtitle,
  HologramTextContainer,
  HologramTitle,
  PirateTakeoverBanner,
} from "./LandingPage.styles";

import pirateTakeoverBanner from "../../images/pirateTakeover.png";

const LandingPage = () => {
  return (
    <Container id="landing">
      <Hologram>
        <HologramContent>
          <HologramTextContainer id="about">
            <HologramTitle>INCOMING ATTACK</HologramTitle>
            <HologramSubtitle>
              Honey Brasco’s space pirates are invading the Dino Babies
              universe! These ruthless scoundrels are one of the many
              antagonists in the upcoming animated series Dino Babies in Space,
              an action comedy from the mind of Matt Bolinger (Nickelodian,
              Warner Bros).
              <br />
              <br />
              In Season 2 of Dino Babies NFT Project, the space pirates have a
              helping hand in creating an on-chain idle game. This play-to-earn
              game is the only way to earn Dino Eggs, which allow fans to
              connect with the show and help decide the production process.
            </HologramSubtitle>
          </HologramTextContainer>
        </HologramContent>
      </Hologram>
      <PirateTakeoverBanner src={pirateTakeoverBanner} />
    </Container>
  );
};

export default LandingPage;
