import { useEffect, useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

import {
  Button,
  Container,
  ContentContainer,
  InfoSection,
  MintButton,
  MintButtonContainer,
  Minus,
  Num,
  Paragraph,
  Plus,
  TextContainer,
  ContentImage,
  MintTypeSelectorContainer,
  MintTypeSelector,
} from "./MintPage.styles";

import pirate from "../../images/pirateMint.png";

import pirateAbi from "../../contracts/pirateAbi.json";
import eggAbi from "../../contracts/eggAbi.json";

import { PIRATE_ADDRESS, EGG_ADDRESS } from "../../constants";

const MintPage = () => {
  // mint related state variables
  const [mintNum, setMintNum] = useState(1);
  const [pirateNum, setPirateNum] = useState(0);

  const [numMintedWithEth, setNumMintedWithEth] = useState(0);
  const [numMintedWithEggs, setNumMintedWithEggs] = useState(0);

  const [eggCount, setEggCount] = useState(0);
  const [eggApprovalStatus, setEggApprovalStatus] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);

  // loading states
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // state change for which type of mint
  const [type, setType] = useState<"egg" | "eth" | "public">("egg");

  const pirateSupply = 4255;
  const pirateEggSupply = 3388;

  const { account, library } = useWeb3React<Web3Provider>();

  useEffect(() => {
    if (library && account) {
      // initialize the contracts
      const contract = new ethers.Contract(
        PIRATE_ADDRESS,
        pirateAbi,
        library?.getSigner()
      );

      const eggContract = new ethers.Contract(
        EGG_ADDRESS,
        eggAbi,
        library.getSigner()
      );

      // calculate balance of egg token of current address.
      // find how many eggs this account has
      eggContract
        .balanceOf(account)
        .then((res: ethers.BigNumber) => {
          const currentEggAmount = parseFloat(
            ethers.utils.formatUnits(res, 18)
          );
          setEggCount(parseFloat(currentEggAmount.toFixed(3)));
        })
        .catch(console.error);

      // checking the total supply and num minted with eggs and eth
      updateSupplies(contract);

      // find current eth balance
      library
        .getBalance(account)
        .then((balance) => {
          // convert a currency unit from wei to ether
          const balanceInEth = ethers.utils.formatEther(balance);
          setEthBalance(parseFloat(parseFloat(balanceInEth).toFixed(3)));
        })
        .catch(console.error);

      // get the current allowance
      eggContract
        .allowance(account, PIRATE_ADDRESS)
        .then((res: ethers.BigNumber) => {
          const approved = parseFloat(ethers.utils.formatUnits(res, 18));
          if (approved < mintNum * 56) {
            //
          } else {
            setEggApprovalStatus(true);
          }
        })
        .catch(console.error);

      // listen to transfer event
      contract.on("Transfer", (from: string) => {
        if (from === "0x0000000000000000000000000000000000000000") {
          updateSupplies(contract);

          contract
            .walletOfOwner(account)
            .then((res: any[]) => {
              setPirateNum(res.length);
            })
            .catch(console.error);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, library]);

  function updateSupplies(contract: ethers.Contract) {
    contract
      .walletOfOwner(account)
      .then((res: any[]) => setPirateNum(res.length))
      .catch(console.error);
    contract
      .totalSupply()
      .then((res: any) => setTotalSupply(parseInt(res.toString())))
      .catch(console.error);
    contract
      .numMintedWithEth()
      .then((res: any) => setNumMintedWithEth(parseInt(res.toString())))
      .catch(console.error);
    contract
      .numMintedWithEggs()
      .then((res: any) => setNumMintedWithEggs(parseInt(res.toString())))
      .catch(console.error);
  }

  const approveEgg = async () => {
    if (library) {
      const eggContract = new ethers.Contract(
        EGG_ADDRESS,
        eggAbi,
        library.getSigner()
      );

      setLoading(true);
      await eggContract.approve(PIRATE_ADDRESS, ethers.constants.MaxUint256);
      setEggApprovalStatus(true);
      setLoading(false);
    }
  };

  const eggMint = async () => {
    if (account && library) {
      try {
        const contract = new ethers.Contract(
          PIRATE_ADDRESS,
          pirateAbi,
          library.getSigner()
        );
        const eggContract = new ethers.Contract(
          EGG_ADDRESS,
          eggAbi,
          library.getSigner()
        );

        let eggAllowanceAmount = await eggContract.allowance(
          account,
          PIRATE_ADDRESS
        );
        eggAllowanceAmount = parseFloat(
          ethers.utils.formatUnits(eggAllowanceAmount, 18)
        );
        if (eggAllowanceAmount < mintNum * 56) {
          // return and warn if the user doesn't have enough allowance (this should be a fallback)
          alert("execution reverted: not enough allowance");
          return;
        }

        const tx = await contract.mintWithEggs(mintNum);

        setLoading(true);
        await tx.wait();
        setLoading(false);

        setSuccess(true);
      } catch (e: any) {
        alert(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
        setLoading(false);
        setError(true);
        // @ts-ignore
        setErrorMsg(
          e.error?.message.replace("execution reverted: ", "") || e.message
        );
      }
    }
  };

  return (
    <Container>
      <MintTypeSelectorContainer>
        <MintTypeSelector
          active={type === "egg"}
          onClick={() => setType("egg")}
        >
          Mint with Dino Eggs
        </MintTypeSelector>
      </MintTypeSelectorContainer>
      <ContentContainer>
        <ContentImage src={pirate} />
        <TextContainer>
          <Paragraph>
            {`You have ${eggCount} Dino Eggs.`}
            <br />
            {`You can mint a max of ${Math.floor(
                eggCount / 56
            )} Pirates at 56 DinoEggs each.`}
          </Paragraph>

          <InfoSection>
            {`${pirateEggSupply - numMintedWithEggs}/${pirateSupply} total Pirates left. `}
            <br />
            <br />
          </InfoSection>
          <MintButtonContainer>
            <MintButton>
              <Minus
                onClick={() => {
                  setMintNum(mintNum > 1 ? mintNum - 1 : 1);
                }}
              >
                -
              </Minus>
              <Num>{mintNum}</Num>
              <Plus
                onClick={() => {
                  setMintNum(
                      mintNum + 1 > Math.floor(eggCount / 56)
                          ? Math.floor(eggCount / 56)
                          : mintNum + 1
                  );
                }}
              >
                +
              </Plus>
            </MintButton>
            <Button
              onClick={() => {
                eggMint();
              }}
              disabled={
                loading ||
                totalSupply >= pirateSupply ||
                (type === "egg" && eggCount < 56) ||
                (type === "egg" && !eggApprovalStatus)
              }
            >
              MINT
            </Button>
            <Button
                onClick={() => {
                  approveEgg();
                }}
                disabled={
                  loading ||
                  totalSupply >= pirateSupply ||
                  (type === "egg" && eggCount < 56) ||
                  eggApprovalStatus
                }
                hidden={
                  type === "eth"
                }
            >
              {eggApprovalStatus ? "APPROVED" : "APPROVE"}
            </Button>
          </MintButtonContainer>
          {success && <Paragraph>Successfully minted!</Paragraph>}
          {error && <Paragraph>{errorMsg}</Paragraph>}
          {/*<OpenseaButton>AVAILABLE ON OPENSEA</OpenseaButton>*/}
        </TextContainer>
      </ContentContainer>
    </Container>
  );
};

export default MintPage;
