import { Container, Video } from "./VideoBackground.styles";

const VideoBackground = () => {
  return (
    <Container>
      <Video src="https://www.youtube.com/embed/39U0X0hNIPQ"/>
    </Container>
  );
};

export default VideoBackground;
