import ReactDOM from 'react-dom';

import {
  Container,
  Content,
  Message,
  Button,
  Image
} from "./Modal.styles";

import closed from "../../images/shop/closed.svg";

const Modal = ({title, message, openModal, toggleModal}: any) => {
  const modal = (
    <Container>
      <Content>
        <Button type="button" onClick={toggleModal}>
          <Image src={closed} width="24" height="24" />
        </Button>
        <Message>{message}</Message>
      </Content>
    </Container>
  )

  return openModal ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
