import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eb8b78;
`;

export const Title = styled.div`
  width: 75%;
  text-align: left;
  font-size: 4.5rem;
  font-family: "Jura-Medium";
  margin: 1rem;
  @media ${breakPoints.phoneOnly} {
    font-size: 2.25rem;
    width: 90%;
  }
`;

export const TeamContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10rem;
  @media ${breakPoints.tabletPortraitUp} {
    width: 95%;
    margin-bottom: 7rem;
    justify-content: space-around;
  }
  @media ${breakPoints.phoneOnly} {
    width: 90%;
    margin-bottom: 5rem;
    justify-content: space-between;
  }
`;
