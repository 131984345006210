// CONSTANT ADDRESSES
const ZIGGY_ADDRESS = "0xf9d53E156fE880889E777392585FEb46D8D840f6";
const EGG_ADDRESS = "0x0aa80dd6058d7730aeaea67c3525e7a61cda9725";
const DISTRIBUTOR_ADDRESS = "0xc0C0B077381CBA887B368E1F06f658a6Ab598929";
const PIRATE_ADDRESS = "0xbcdca41219ccbd72381b2748a8b9b96769a9084f";
const PIRATE_STAKING_ADDRESS = "0x22c71f8b4ecc05c84cd3b9b51376fc816098802c";
const ZIGGY_STAKING_ADDRESS = "0x78a8a1decfd67902e04ba32b3dbcd6bf5244eaf4";

// ENDPOINTS
const CLAIM_ENDPOINT = "https://nyp1y50nnd.execute-api.us-east-2.amazonaws.com/prod/claim";
const HISTORY_ENDPOINT = "https://nyp1y50nnd.execute-api.us-east-2.amazonaws.com/prod/history";
const HOLDINGS_ENDPOINT = "https://nyp1y50nnd.execute-api.us-east-2.amazonaws.com/prod/holdings";
const DISCORD_VERIFICATION_ENDPOINT = "https://dino-babies-verification.herokuapp.com/callback";
const SHOP_ENDPOINT = "https://nyp1y50nnd.execute-api.us-east-2.amazonaws.com/prod/shop";
const ORDER_ENDPOINT = "https://nyp1y50nnd.execute-api.us-east-2.amazonaws.com/prod/order";

export {
  ZIGGY_ADDRESS,
  EGG_ADDRESS,
  DISTRIBUTOR_ADDRESS,
  CLAIM_ENDPOINT,
  HISTORY_ENDPOINT,
  HOLDINGS_ENDPOINT,
  PIRATE_ADDRESS,
  PIRATE_STAKING_ADDRESS,
  ZIGGY_STAKING_ADDRESS,
  DISCORD_VERIFICATION_ENDPOINT,
  SHOP_ENDPOINT,
  ORDER_ENDPOINT,
};
