import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52.1vw;
  background-size: 100% 100%;
  background-image: url("images/video-background.jpg");
`;

export const Video = styled.iframe`
  width: 50%;
  height: 60%;
`;
