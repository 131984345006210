import FaqModule from "../../../Faq/subcomponent/FaqModule/FaqModule";
import {
  Container,
  Description,
  FAQContainer,
  FaqText,
  Link,
  Title,
} from "./GameExplainerSection.styles";

const FAQs = [
  {
    title: "Ziggy Actions",
    content: (
      <>
        <FaqText>
          Ziggys generate 1 Egg a day passively. As long as they are in your
          wallet, you will generate an egg.
        </FaqText>
        <br />
        <FaqText>A Ziggy when staked generates 2 Eggs a day.</FaqText>
        <br />
        <FaqText>
          All Eggs generated go into your Unclaimed Pool of Eggs located here on
          Dinobabies.io. In order to Save your Eggs you have to claim them.
        </FaqText>
      </>
    ),
  },
  {
    title: "Pirate Actions",
    content: (
      <>
        <FaqText>Pirates do nothing when unstaked.</FaqText>
        <br />
        <FaqText>
          When staked, pirates go on raiding missions to steal unclaimed eggs.
        </FaqText>
        <br />
        <FaqText>
          A staked pirate goes on a raid between every 16-32 hours. Each pirate
          will attempt to steal unclaimed eggs from a random wallet. Pirates
          steal between 0-90% (random) of someone’s unclaimed eggs. There is an
          additional 5% chance to fail - A critical failure.
        </FaqText>
        <br />
        <FaqText>
          All stolen eggs go to your Unclaimed Pool of Eggs, so they can be
          stolen back if you don’t claim!
        </FaqText>
        <br />
        <FaqText>
          No one will ever steal your claimed eggs. Those are yours forever.
        </FaqText>
      </>
    ),
  },
];

const GameExplainerSection = () => {
  return (
    <Container>
      <Title>FAQs</Title>
      <Description>
        Egg Game is your way to generate and amass $DinoEggs, the fuel behind
        all parts of the Dino Babies in Space ecosystem. The more Eggs you have,
        the more you can do and the larger role you play in the Community
        Wallet, a producer on the feature animated series. Here is how you can
        win Eggs:
      </Description>
      <FAQContainer>
        {FAQs.map((faq) => (
          <FaqModule title={faq.title} content={faq.content} key={faq.title} />
        ))}
      </FAQContainer>
    </Container>
  );
};

export default GameExplainerSection;
