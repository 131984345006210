import { useState } from 'react';

export const useModal = () => {
  const [openModal, setIsShown] = useState<boolean>(false);
  const toggleModal = () => setIsShown(!openModal);
  return {
    openModal,
    toggleModal,
  };
};
