import styled from "styled-components";
import breakPoints from "../../../../constants/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 3rem;
  font-family: "Jura-Medium";

  @media ${breakPoints.phoneOnly} {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem 0;
  }
`;

export const MemberImage = styled.img`
  width: 11.5rem;
  height: 11.5rem;

  @media ${breakPoints.phoneOnly} {
    width: 4.875rem;
    height: 4.875rem;
  }
`;

export const MemberName = styled.p`
  font-weight: 600;
  margin-bottom: 0;
  @media ${breakPoints.phoneOnly} {
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;
  }
`;

// TODO: change to open sans
export const MemberSlug = styled.p`
  margin-top: 0;
  font-weight: 200;

  @media ${breakPoints.phoneOnly} {
    margin-bottom: 0.1rem;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 0.5rem;
  @media ${breakPoints.phoneOnly} {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;
  }
`;

export const SocialLink = styled.div`
  padding: 0 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }

  @media ${breakPoints.phoneOnly} {
    padding-left: 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.35rem;
  height: 11.35rem;
  border-radius: 50%;
  box-shadow: 0 0 0 2px black;
  border: 2px solid black;
  @media ${breakPoints.phoneOnly} {
    width: 4.75rem;
    height: 4.75rem;
    box-shadow: 0 0 0 1px black;
    border: 1px solid black;
    margin-right: 1rem;
  }
`;

export const TextContainer = styled.div``;

export const ContentContainer = styled.div`
  text-align: center;
  @media ${breakPoints.phoneOnly} {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }
`;
