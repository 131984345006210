import { useCallback, useEffect, useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";

import "./App.css";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import Faq from "./components/Faq/Faq";
import Footer from "./components/Footer/Footer";
import LandingDivider from "./components/LandingDivider/LandingDivider";
import LandingPage from "./components/LandingPage/LandingPage";
import Navbar from "./components/Navbar/Navbar";
import Roadmap from "./components/Roadmap/Roadmap";
import RoadmapDivider from "./components/RoadmapDivider/RoadmapDivider";
import Team from "./components/Team/Team";
import TeamDivider from "./components/TeamDivider/TeamDivider";
import VideoBackground from "./components/VideoBackground/VideoBackground";
import SUPPORTED_WALLET_PROVIDERS, {
  AbstractConnector,
  WalletProvider,
} from "./constants/supportedWalletProviders";
import { loadLastAccount, saveLastAccount } from "./features/wallet/walletApi";
import {
  selectWallet,
  setActiveProvider,
  setWalletConnected,
} from "./features/wallet/walletSlice";
import Overlay from "./components/Overlay/Overlay";

// react-router
import { Routes, Route } from "react-router-dom";
import MintPage from "./components/MintPage/MintPage";
import StakePage from "./components/StakePage/StakePage";
import CommunityWallet from "./components/CommunityWallet/CommunityWallet";
import Verify from "./components/Verify/Verify";
import ShopPage from "./components/Shop/ShopPage";
require("typeface-orbitron");

const App = () => {
  // Redux
  const dispatch = useAppDispatch();

  const [showOverlay, setShowOverlay] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [activated, setActivated] = useState(false);
  // Local component state
  const [, setIsActivating] = useState<boolean>(false);

  const [connector, setConnector] = useState<AbstractConnector>();
  const [provider, setProvider] = useState<WalletProvider>();

  const { providerName } = useAppSelector(selectWallet);

  const handleEscKey = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        setShowOverlay(false);
      }
    },
    [setShowOverlay]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKey, false);
    return () => {
      document.removeEventListener("keydown", handleEscKey, false);
    };
  }, [handleEscKey]);

  const { account, active, chainId, library, activate } =
    useWeb3React<Web3Provider>();

  useEffect(() => {
    setWalletAddress(account!);
  }, [account]);

  // Auto-activate if user has connected before on (first render)
  useEffect(() => {
    const lastConnectedAccount = loadLastAccount();
    if (lastConnectedAccount?.address) {
      setIsActivating(true);
      const connector = lastConnectedAccount.provider.getConnector();
      setConnector(connector);
      setProvider(lastConnectedAccount.provider);
      activate(connector)
        .then(() => {
          setActivated(true);
        })
        .finally(() => {
          setIsActivating(false);
        });
    }
  }, [activate, activated]);

  // Side effects for connecting a wallet from SwapWidget

  useEffect(() => {
    if (providerName) {
      const provider = SUPPORTED_WALLET_PROVIDERS.find(
        (provider) => provider.name === providerName
      );
      setProvider(provider);
      setConnector(provider!.getConnector());
    }
  }, [providerName]);

  useEffect(() => {
    if (active && account && chainId && library && connector && provider) {
      // Dispatch a general action to indicate wallet has changed
      dispatch(
        setWalletConnected({
          chainId,
          address: account,
        })
      );
      saveLastAccount(account, provider);
    }
  }, [account, active, chainId, connector, dispatch, library, provider]);

  return (
    <>
      <Navbar
        onClick={walletAddress ? console.log : setShowOverlay}
        walletAddress={walletAddress}
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <LandingPage />
              <LandingDivider />
              <VideoBackground />
              <RoadmapDivider />
              <Roadmap />
              <CommunityWallet />
              <TeamDivider />
              <Team />
            </>
          }
        />
        <Route path="mint" element={<MintPage />} />
        <Route path="eggs" element={<StakePage />} />
        <Route path="faq" element={<Faq />} />
        <Route path="verify" element={<Verify />} />
        <Route path="shop" element={<ShopPage />} />
      </Routes>

      <Footer />
      {showOverlay && (
        <Overlay
          onProviderSelected={(provider: any) => {
            dispatch(setActiveProvider(provider.name));
            activate(provider.getConnector()).finally(() =>
              setShowOverlay(false)
            );
          }}
        />
      )}
    </>
  );
};

export default App;
