import {
  Container,
  TextContainer,
  Title,
  Card,
  CardTitle,
  CardContainer,
  CardDescription,
  Button,
  ButtonContainer,
} from "./CommunityWallet.styles";


const CommunityWallet = () => {
  const treasuryAmount = 141;

  return (
    <Container id="faq">
      <Title>Community Wallet</Title>
      <CardContainer>
        <Card>
          <CardTitle>Estimated value of wallet:</CardTitle>
          <CardDescription>{treasuryAmount} eth</CardDescription>
        </Card>
      </CardContainer>
      <TextContainer>
        The Dino Babies Community Wallet is a shared fund among all Dino Holders
        in the Dino Babies Community and a partial owner in the Dino Babies in
        Space Animated Series.
        <br />A portion of every NFT sale goes directly into the wallet and
        members can vote collectively on what to do with the funds. This
        DAO-like structure also can vote on nearly every element of the project
        and change the course of it fundamentally.
        <br /> <br />
        There's a lot of power in holding a Dino Egg. We hope you use it wisely.
      </TextContainer>
      <ButtonContainer>
        <Button href="https://snapshot.org/#/dinobabies.eth" target="_blank">
          DAO Proposals
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default CommunityWallet;