import { FaDiscord, FaTwitter } from "react-icons/fa";
import {
  BrandingContainer,
  Container,
  LeftContainer,
  OpenSea,
  RightContainer,
  SocialLink,
  SocialsContainer,
  Title,
  Copyright,
  MiddleStrip,
  TextContainer,
} from "./Footer.styles";
import { BsMedium } from "react-icons/bs";

const Footer = () => {
  return (
    <Container>
      <MiddleStrip>{/* <ZiggyButt src={ziggyButt} /> */}</MiddleStrip>
      <TextContainer>
        <LeftContainer>
          <BrandingContainer>
            <Title>DINO BABIES</Title>
          </BrandingContainer>
          <Copyright>
            ©Dino Babies. <br />
            All rights reserved.
          </Copyright>
        </LeftContainer>
        <RightContainer>
          <SocialsContainer>
            <SocialLink>
              <FaDiscord
                size={24}
                onClick={() =>
                  window.open(
                    "https://discord.com/channels/903933648250740816/903947332712812565/912491075506155561",
                    "_blank"
                  )
                }
              />
            </SocialLink>
            <SocialLink>
              <FaTwitter
                size={24}
                onClick={() =>
                  window.open("https://twitter.com/dinobabiesNFT", "_blank")
                }
              />
            </SocialLink>
            <SocialLink>
              <OpenSea
                onClick={() =>
                  window.open(
                    "https://opensea.io/collection/dinobabies",
                    "_blank"
                  )
                }
              />
            </SocialLink>
            <SocialLink>
              <BsMedium
                size={24}
                onClick={() =>
                  window.open("https://medium.com/@dinobabies", "_blank")
                }
              />
            </SocialLink>
          </SocialsContainer>
        </RightContainer>
      </TextContainer>
    </Container>
  );
};

export default Footer;
