import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  width: 100%;
  background-color: rgb(200, 102, 143, 0.7);
`;

export const Title = styled.div`
  width: 75%;
  text-align: left;
  font-size: 4.5rem;
  font-family: "Jura-Medium";
  margin: 1rem;
  @media ${breakPoints.phoneOnly} {
    font-size: 2.25rem;
    width: 90%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  line-height: 1.5rem;

  @media ${breakPoints.phoneOnly} {
    width: 90%;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 75%;
  margin: 2rem 0;

  @media ${breakPoints.phoneOnly} {
    flex-direction: column;
    margin: 1rem 0;
    width: 90%;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 20rem;
  height: 6rem;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  background: white;
  margin-right: 2rem;
  box-shadow: 5px 6px 2px 1px rgba(137, 32, 76, 0.5);

  @media ${breakPoints.phoneOnly} {
    width: 16rem;
    height: 6rem;
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

export const CardDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const ZiggyImage = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`;

export const CardTitle = styled.p`
  margin: 0.5rem 0;
`;

export const CardDescription = styled.p`
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0.5rem 0;
`;

export const ButtonContainer = styled.div`
  width: 75%;
  margin-top: 2rem;

  @media ${breakPoints.phoneOnly} {
    width: 90%;
  }
`;

export const Button = styled.a`
  line-height: 3rem;
  font-family: "Jura-Medium";
  background: black;
  border-radius: 0.5rem;
  border: 3px rgba(255, 72, 143, 1) solid;
  padding: 1rem 2rem;
  color: white;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
`;
