import {
  Anchor,
  Container,
  List,
  ListContainer,
  Logo,
  LogoContainer,
  XContainer,
  ZiggyButt,
} from "./MobileMenu.styles";
import logo from "../../images/logo.svg";
import { ImCross } from "react-icons/im";
import ziggyButt from "../../images/ziggyButt.png";
import { StyledRouterLink } from "../Navbar/Navbar.styles";
import { useLocation } from "react-router-dom";

const MobileMenu = ({ isOpen, setIsOpen }: any) => {
  const location = useLocation();

  return (
    <Container isOpen={isOpen}>
      <XContainer>
        <ImCross size={24} onClick={() => setIsOpen(false)} />
      </XContainer>
      <LogoContainer>
        <Logo src={logo} />
      </LogoContainer>
      <ListContainer>
        <List>
          <StyledRouterLink to="/eggs" onClick={() => setIsOpen(false)}>
            Eggs and Staking
          </StyledRouterLink>
        </List>
        <List>
          <StyledRouterLink to="/shop" onClick={() => setIsOpen(false)}>
            Shop
          </StyledRouterLink>
        </List>
        <List>
          {location.pathname !== "/" ? (
              <StyledRouterLink to="/#about" onClick={() => setIsOpen(false)}>
                About
              </StyledRouterLink>
          ) : (
              <Anchor href="#about" onClick={() => setIsOpen(false)}>
                About
              </Anchor>
          )}
        </List>
        <List>
          <StyledRouterLink to="/faq" onClick={() => setIsOpen(false)}>
            FAQ
          </StyledRouterLink>
        </List>
        <List>
          {location.pathname !== "/" ? (
              <StyledRouterLink to="/#roadmap" onClick={() => setIsOpen(false)}>
                Roadmap
              </StyledRouterLink>
          ) : (
              <Anchor href="#roadmap" onClick={() => setIsOpen(false)}>
                Roadmap
              </Anchor>
          )}
        </List>
        <List>
          {location.pathname !== "/" ? (
              <StyledRouterLink to="/#team" onClick={() => setIsOpen(false)}>
                Team
              </StyledRouterLink>
          ) : (
              <Anchor href="#team" onClick={() => setIsOpen(false)}>
                Team
              </Anchor>
          )}
        </List>
      </ListContainer>
      <ZiggyButt src={ziggyButt} />
    </Container>
  );
};

export default MobileMenu;
