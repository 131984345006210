import styled from "styled-components";
import breakPoints from "../../../../constants/breakpoints";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const NavigationBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Jura-Medium";
`;

export const StateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type StateProps = {
  active: boolean;
};

export const State = styled.p<StateProps>`
  text-align: center;
  border-bottom: 1px solid black;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-bottom: 0;
  background-color: ${(props) => (props.active ? "#15D6BA" : "transparent")};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
`;

export const SelectedNum = styled.p``;

export const ApprovePrompt = styled.div``;

type ButtonProps = {
  disabled: boolean;
};

export const Button = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 2px solid #ff488f;
  border-radius: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: 0.25s;
  pointer-events: ${(props) => (props.disabled ? "none" : "default")};
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};

  &:hover {
    background-color: #ff488f;
    color: white;
  }

  @media ${breakPoints.phoneOnly} {
    width: 3.5rem;
  }
`;

export const AssetContainer = styled.div`
  position: relative;
  min-height: 70vh;
  width: 100%;
  background-color: #15d6ba;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 0;

  @media ${breakPoints.phoneOnly} {
    justify-content: center;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 1001;
`;

type ApproveButtonProps = {
  disabled: boolean;
};

export const ApproveButton = styled.div<ApproveButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 2px solid #ff488f;
  border-radius: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
  transition: 0.25s;
  background-color: white;
  color: black;
  pointer-events: ${(props) => (props.disabled ? "none" : "default")};
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
`;
