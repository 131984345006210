import Shop from "./Shop";
import Orders from "./Orders";
import {
    Container,
    ShopContainer,
    Selector,
    SelectorContainer, Message
} from "./Shop.styles";
import {useEffect, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import axios from "axios";
import {SHOP_ENDPOINT} from "../../constants";
import Modal from "../Modal/Modal";
import {useModal} from "../Modal/useModal";


const ShopPage = () => {
    // page type
    const [type, setType] = useState<"shop" | "orders" >("shop");

    const [products, setProducts] = useState<any[]>([]);
    const { account, library } = useWeb3React<Web3Provider>();
    const [error, setError] = useState<string>("");
    const { openModal, toggleModal } = useModal();

    const fetchShopItems = async () => {
        try {
            const { data } = await axios.get(SHOP_ENDPOINT);
            setProducts(data);
        } catch (err: any) {
            setError(err.message);
            toggleModal();
        }
    };

    useEffect(() => {
        fetchShopItems();
    }, []);

    const renderView = () => {
        switch (type) {
            case "shop":
                return <Shop
                        products={products}
                        setProducts={setProducts}/>;
            case "orders":
                const productMap = new Map<number, any>();
                products.forEach(function(product) {
                    productMap.set(product.productId, product);
                });
                return <Orders products={productMap}/>;
            default:
                break;
        }
    };

    return (
        <ShopContainer>
            {!account && (
                <Message>
                    You have to connect your wallet before purchasing from the store
                </Message>
            )}
            <Container>
                <SelectorContainer>
                    <Selector
                        active={type === "shop"}
                        onClick={() => setType("shop")}
                    >
                        Dino Shop
                    </Selector>
                    <Selector
                        active={type === "orders"}
                        onClick={() => setType("orders")}
                    >
                        Order History
                    </Selector>
                </SelectorContainer>
            </Container>
            {renderView()}
            <Modal
                message={error}
                openModal={openModal}
                toggleModal={toggleModal}
            />
        </ShopContainer>
    );
};

export default ShopPage;