import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const ShopContainer = styled.div`
  background-color: #e4578e;
  min-height: calc(100vh - 276px);
`;

export const Container = styled.div`
  max-width: 1062px;
  width: 100%;
  padding: 1.5rem 0;
  margin: 0 auto;
`;

export const Message = styled.div`
  background-color: #86f9de;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 700;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  margin-bottom: 1.5rem;
  box-sizing: border-box;

  @media ${breakPoints.phoneOnly} {
    padding: 0 1rem;
    flex-flow: column;
  }
`;

export const Title = styled.div`
  font-family: "Jura-Medium", Roboto;
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;
`;

export const BalanceContainer = styled.div`
  display: flex;
  font-size: 1.5rem;
  align-items: center;
`;

export const BalanceText = styled.div`
  margin-right: 0.75rem;
`;

export const BalanceImage = styled.img`
  display: inline-block;
`;

export const Balance = styled.div`
  margin-left: 0.25rem;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  column-gap: 2rem;
  row-gap: 3rem;
  background-color: #c64476;
  padding: 2rem;
`;

export const Item = styled.div`
  border-radius: 12px;
  box-shadow: 14px 14px 0px rgba(116, 16, 55, 0.35);
  overflow: hidden;
  max-width: 291px;
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 1rem;
  height: 100%;
  background-color: #fff;
`;

export const Name = styled.div`
  font-family: "Jura-Medium", Roboto;
  font-size: 1.125rem;
  max-width: 160px;
  width: 100%;
  margin-bottom: 15px;
`;

export const Description = styled.div`
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 300px;
  width: 100%;
`;

export const Price = styled.div`
  font-family: "Jura-Medium", Roboto;
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-bottom: 15px;
`;

export const Button = styled.button`
  font-family: "Jura-Medium", Roboto;
  background-color: #fff;
  border: 3px solid #ff488f;
  border-radius: 10px;
  padding: 0.5rem;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1.125rem;
  margin-top: auto;
  font-weight: 700;
  cursor: pointer;
`;

export const Stock = styled.div`
  width: 100%;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 15px;
  overflow-wrap: break-word;
`;

export const OrderField = styled.p`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bolder;
  text-decoration: underline;
`;

export const OrderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 7.8125rem;
  height: 5.125rem;
  border-radius: 0.5rem;
  border: 2px transparent solid;
  font-size: 0.875rem;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 10px;
`;

export const SelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60rem;
  font-family: "Jura-Medium";
  margin-top: 2rem;

  @media ${breakPoints.phoneOnly} {
    width: 20rem;
  }
`;

type SelectorProps = {
    active: boolean;
};

export const Selector = styled.div<SelectorProps>`
  width: 50%;
  text-align: center;
  border-bottom: 1px solid black;
  padding: 2rem 0;
  cursor: pointer;
  color: #fff;
  font-family: "Jura-Medium", Roboto;
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;
  background-color: ${(props) => (props.active ? "#c24a79" : "transparent")};
`;