import axios from "axios";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  ShopContainer,
  Container,
  Message,
  Header,
  BalanceContainer,
  BalanceText,
  BalanceImage,
  Balance,
  Content,
  Item,
  Image,
  ItemContent,
  Name,
  Price,
  Description,
  Button,
  Stock,
  Input, OrderButton,
} from "./Shop.styles";
import egg from "../../images/shop/egg.png";
import Modal from "../Modal/Modal";
import { useModal } from "../Modal/useModal";

import eggAbi from "../../contracts/eggAbi.json";
import { SHOP_ENDPOINT, EGG_ADDRESS, ORDER_ENDPOINT } from "../../constants";
import {Minus, Num, Plus} from "../MintPage/MintPage.styles";

type ShopProps = {
  products: any[];
  setProducts: React.Dispatch<React.SetStateAction<any[]>>;
}

const tempId = "znXLV7aSnJfXtykPBWxzHvzYHU22Czn48fNvtTJV";

const Shop = ({
    products,
    setProducts,
  }: ShopProps) => {
  const [eggCount, setEggCount] = useState(0);
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [modalConfirmation, setModalConfirmation] = useState<any>(null);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [orderLimits, setOrderLimits] =  useState<Map<number, number>>(new Map<number, number>());
  const [orderQtys, setOrderQtys] =  useState<Map<number, number>>(new Map<number, number>());

  const { openModal, toggleModal } = useModal();
  const { account, library } = useWeb3React<Web3Provider>();

  const eggContract = useMemo(() => {
    return new ethers.Contract(EGG_ADDRESS, eggAbi, library?.getSigner());
  }, [library]);

  const updateEggCount = useCallback(() => {
    if (!account) {
      return;
    }

    eggContract
      .balanceOf(account)
      .then((res: ethers.BigNumber) => {
        const currentEggAmount = parseFloat(ethers.utils.formatUnits(res, 18));
        setEggCount(parseFloat(currentEggAmount.toFixed(3)));
      })
      .catch(console.error);
  }, [account, eggContract, setEggCount]);

  const fetchShopItems = async () => {
    try {
      const { data } = await axios.get(SHOP_ENDPOINT);
      setProducts(data);
    } catch (err: any) {
      setError(err.message);
      toggleModal();
    }
  };

  const fetchOrderLimits = async () => {
    try {
      const { data } = await axios.get(`${ORDER_ENDPOINT}/${account}`);
      let map = new Map<number, number>()
      for (let value in data) {
        map.set(Number(value), data[value])
      }
      setOrderLimits(map);
    } catch (err: any) {
      setError(err.message);
      toggleModal();
    }
  };

  const setOrderQty = (key: number, value: number) => {
    setOrderQtys((prev: Map<number,number>) => new Map(prev).set(key, value));
  }

  useEffect(() => {
    fetchOrderLimits();
  }, []);

  useEffect(() => {
    fetchShopItems();
  }, []);

  useEffect(() => {
    updateEggCount();
  }, [updateEggCount]);

  const popup = (productIndex: number, productId: number) => {
    setSuccess("");
    setError("");

    const confirmation = (
      <div>
        <p>
          Please enter your discord username as <strong>username#id</strong> to
          {products[productIndex].orderType === "raffle" ? " complete the entry:" :" continue your purchase:"}
        </p>

        <Input
          type="text"
          placeholder="username#id"
          defaultValue={window.localStorage.getItem("discordUsername") || ""}
          onChange={(e) =>
            window.localStorage.setItem("discordUsername", e.target.value)
          }
        />

        <p>
          Buying: {orderQtys.get(productId) === undefined ? 0 : orderQtys.get(productId)} - " {products[productIndex].productName}"
        </p>

        <Button onClick={() => buy(productIndex)}>{products[productIndex].orderType === "raffle" ? "Enter Now" :"Buy Now"}</Button>
      </div>
    );

    setModalConfirmation(confirmation);
    toggleModal();
  };

  const getOrderLimit = (productId: number) => {
    let maxQty = orderLimits.get(productId);
    return maxQty === undefined ? 1 : maxQty;
  }

  const buy = async (productIndex: number) => {
    setSuccess("");
    setError("");
    setShowLoader(true);
    setModalConfirmation(null);

    try {
      const product = products[productIndex];

      let raffle = product.orderType === "raffle";

      if (raffle) {
        await axios.post(`${ORDER_ENDPOINT}?id=${tempId}`, {
          wallet: account,
          productId: product.productId,
          discordId: window.localStorage.getItem("discordUsername") || "",
          qty: 1,
          tx: ethers.utils.id(account + product.productId),
        });
        window.localStorage.setItem(account + product.productId, "entered");
      } else {
        let qty = orderQtys.get(product.productId);
        qty = qty === undefined ? 0 : qty;

        await axios.put(`${SHOP_ENDPOINT}/${product.productId}?id=${tempId}&qty=${qty}`);

        const price = product.price * qty;
        const gasLimit = await eggContract.estimateGas.transfer(
            EGG_ADDRESS,
            ethers.utils.parseEther(price.toString())
        );
        const gasPrice = await library?.getGasPrice();

        const tx = await eggContract.transfer(
            EGG_ADDRESS,
            ethers.utils.parseEther(price.toString()),
            {
              gasLimit,
              gasPrice,
            }
        );

        await axios.post(`${ORDER_ENDPOINT}?id=${tempId}`, {
          wallet: account,
          productId: product.productId,
          discordId: window.localStorage.getItem("discordUsername") || "",
          qty: qty,
          tx: tx.hash,
        });

        const receipt = await tx.wait();

        if (receipt.status !== 1) {
          throw new Error("Transaction failed");
        }
      }

      fetchShopItems();
      updateEggCount();
      fetchOrderLimits();

      setSuccess(product.successMessage);
      setShowLoader(false);
      setModalConfirmation(null);
      toggleModal();
    } catch (err: any) {
      setShowLoader(false);

      const message =
        err.error?.message.replace("execution reverted: ", "") || err.message;

      setError(message);
      toggleModal();
    }
  };

  return (
    <ShopContainer>
      {!account && (
        <Message>
          You have to connect your wallet before purchasing from the store
        </Message>
      )}
      <Container>
        <Header>
          <BalanceContainer>
            <BalanceText>Balance:</BalanceText>
            <BalanceImage src={egg} width={38} height={38} />
            <Balance>{eggCount}</Balance>
          </BalanceContainer>
        </Header>

        <Content>
          {products.map((product, index) => {
            return (
              <Item key={product.productId}>
                <Image
                  width={291}
                  height={221}
                  src={product.image}
                  alt={product.productName}
                />
                <ItemContent>
                  <Name>{product.productName}</Name>
                  <Price>
                    <BalanceImage src={egg} width={38} height={38} />
                    {product.price}
                  </Price>
                  <Description
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                  {product.stock > 0 ? (
                      <Stock>{product.stock} Available </Stock>
                  ) : null}
                  {product.stock > 0 ? (
                    <OrderButton>
                      <p>Quantity:</p>
                      <Minus
                          onClick={() => {
                            let qty = orderQtys.get(product.productId);
                            qty = qty === undefined ? 0 : qty;
                            setOrderQty(product.productId, qty >= 1 ? qty - 1 : 0);
                          }}
                      >
                        -
                      </Minus>
                      <Num>{orderQtys.get(product.productId) !== undefined ? orderQtys.get(product.productId) : 0}</Num>
                      <Plus
                          onClick={() => {
                            let maxQty = getOrderLimit(product.productId);
                            let qty = orderQtys.get(product.productId);
                            qty = qty === undefined ? 0 : qty;
                            setOrderQty(product.productId,
                                qty >= maxQty ? maxQty : qty + 1 > Math.floor(eggCount / product.price)
                                    ? Math.floor(eggCount / product.price)
                                    : qty >= maxQty ? maxQty : qty + 1);
                          }}
                      >
                        +
                      </Plus>
                    </OrderButton>
                  ) : null}
                  {(product.stock === 0 || window.localStorage.getItem(account + product.productId) === "entered") ? (
                    <Stock>{product.orderType === "raffle" ? (product.stock === 0 ? "Not Available" : "Already Entered") : "Not Available"}</Stock>
                  ) : eggCount >= product.price ? (
                    <Button onClick={() => popup(index, product.productId)} disabled={showLoader}>
                      {product.orderType === "raffle" ? "Enter Now" : (showLoader ? "Buying..." : "Buy Now")}
                    </Button>
                  ) : (
                    <Button onClick={() => buy(index)} disabled>
                      Not Enough Eggs
                    </Button>
                  )}
                </ItemContent>
              </Item>
            );
          })}
        </Content>
      </Container>

      <Modal
        message={error || success || modalConfirmation}
        openModal={openModal && !showLoader}
        toggleModal={toggleModal}
      />
    </ShopContainer>
  );
};

export default Shop;
