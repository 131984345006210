import { Container, FAQContainer, FaqText, Link, Title } from "./Faq.styles";
import FaqModule from "./subcomponent/FaqModule/FaqModule";

const FAQs = [
  {
    title: "What are the goals of the Dino Babies NFT project?",
    content: (
      <>
        <FaqText>
          We have three goals regarding the Dino Babies NFT project:
        </FaqText>
        <FaqText>
          1) Grow an online community surrounding animation and media projects
          in the NFT space
        </FaqText>
        <FaqText>
          2) Help produce the Dino Babies in Space animated series
        </FaqText>
        <FaqText>
          3) Serve as a launchpad for emerging artists and animated productions
          in the NFT space
        </FaqText>
        <br />
        <br />
        <FaqText>
          We are going to achieve those goals by building long-lasting
          partnerships with NFT projects that share the same vision, and
          collectively deploying our shared funds in support of growing the
          animation community.
        </FaqText>
      </>
    ),
  },
  {
    title: "What is a play-to-earn idle game?",
    content: (
      <>
        <FaqText>
          An idle game, or an incremental game is a low pressure game that
          allows users to see progress with very little interaction. For the
          Dino Babies NFT Project, we created an idle game on the Ethereum
          Network that starts with the Ziggy generating eggs daily.
        </FaqText>
        <br />
        <FaqText>
          Every season, we plan on introducing new features to the game to
          increase the strategy and complexity all as optional NFTs the user can
          deploy. The end goal is to claim as many Dino Eggs as possible and
          there are many different ways to achieve it.
        </FaqText>
      </>
    ),
  },
  {
    title: "What are Dino Eggs used for? ",
    content: (
      <>
        <FaqText>
          Dino Eggs are the main fuel for everything in the Dino Babies in Space
          universe. The only way you can generate Eggs is through playing the
          Dino Babies Idle game and Eggs have no exchange value for currency.
        </FaqText>
        <br />
        <FaqText>
          We are continually building out additional utility for the Dino Egg.
          Here are a few of the uses that are out or coming out this season:
          <br /> 1) Dino Eggs represent 1 vote each in in the Dino Babies
          Community Wallet
          <br /> 2) Every NFT in the project can be minted with Dino Eggs
          <br /> 3)Some NFTs in the idle game will use eggs to change form or
          abilities
          <br /> 4)Eggs can be exchanged for collaboration allow-list spots
          <br /> 5)The Dino Babies merch store will exchange eggs for coupon
          codes
        </FaqText>
      </>
    ),
  },
  {
    title: "How does the NFT relate to the show?",
    content: (
      <>
        <FaqText>
          The Dino Babies NFT project directly funds and owns a portion of all
          creative rights regarding the Animated series through the use of the
          Community Wallet. That means that by purchasing an NFT, you are
          directly funding the creation of children’s animation, and by
          participating the in the community, you are part of the production
          process.
        </FaqText>
        <br />
        <FaqText>
          The Dino Babies community wallet is the show’s first major producer
          and so many of the show’s financing and strategic spend decisions will
          be put through the proposal process following standard Gnosis DAO
          procedures.
        </FaqText>
        <br />
        <FaqText>
          <Link
            onClick={() =>
              window.open(
                "https://www.studiobinder.com/blog/what-does-a-tv-producer-do/",
                "_blank"
              )
            }
          >
            Check out the following article explaining the role a producer has
            in creating a television show.
          </Link>
        </FaqText>
      </>
    ),
  },
  {
    title: "Where can I buy a Dino Baby NFT?",
    content: (
      <>
        <FaqText>
          All Ziggys are sold out and can only be bought on secondary
          marketplaces. We recommend{" "}
          <Link
            onClick={() =>
              window.open("https://opensea.io/collection/dinobabies", "_blank")
            }
          >
            OpenSea
          </Link>{" "}
          and{" "}
          <Link
            onClick={() =>
              window.open(
                "https://looksrare.org/collections/0xf9d53E156fE880889E777392585FEb46D8D840f6",
                "_blank"
              )
            }
          >
            Looksrare
          </Link>{" "}
          to find your perfect Ziggy.
        </FaqText>
        <br />
        <FaqText>
          Feburary 28th, 2022 8,888 Space Pirates will be released right here on
          Dinobabies.io.
        </FaqText>
        <br />
        <FaqText>
          Each pirate will cost 0.07Ξ or 56 $DinoEggs to mint and the time of
          release is TBA.
        </FaqText>
        <br />
        <FaqText>
          After the sale is closed, you’ll have to find your pirates on the
          secondary market as well.
        </FaqText>
      </>
    ),
  },
  {
    title: "Is there a presale? When is reveal? And other minting logistics…",
    content: (
      <>
        <FaqText>
          There is a presale! The best way to join the presale is own a ziggy on
          2/27/2022. All holders on that date will be given access to the
          presale list. Otherwise, you will have to enter giveaways of presale
          permissions from any of our partners. Check out our twitter and
          discord to learn more about who those partners are.
        </FaqText>
        <br />
        <FaqText>
          Every presale member can mint a max of pirates equal to the Ziggys
          they hold, or just one pirate, whichever is larger. That means if you
          don’t hold a Ziggy but are on the presale list, you can just mint one
          pirate.
        </FaqText>
        <br />
        <FaqText>
          The public mint will take place 24 hours after the presale. All users
          can mint up to 5 pirates per trasnaction either for 0.07Ξ/ea or 56
          $DinoEggs/ea
        </FaqText>
        <br />
        <FaqText>
          Reveal will be 2 days after the close of the public sale.
        </FaqText>
      </>
    ),
  },
  {
    title: "But what is Dino Babies in Space?",
    content: (
      <>
        <FaqText>
          Dino Babies in Space is the show Matt Bolinger has dreamed about
          making for years now. After working as a storyboard artist for major
          networks like Nickelodian and Warner Bros, Matt has learned from every
          cycle of the development process how a show gets made. Dino Babies in
          Space is his pet project that came about as a series of absurd what
          ifs.
        </FaqText>
        <br />
        <FaqText>
          What if the Dinosaurs were not extinct? What if they were as
          intelligent as humans? What if space travel was real and accessible?
        </FaqText>
        <br />
        <FaqText>
          Now with funding from the Dino Babes NFT project we are well on our
          way to getting Dino Babies on TV and beamed straight into your home.
        </FaqText>
      </>
    ),
  },
  {
    title: "I am new to NFT’s where do I start?",
    content: (
      <>
        <FaqText>
          Welcome to the wild west of Web3! There is so much to write but it has
          been explained by great journalists around the globe.
        </FaqText>
        <br />
        <FaqText>
          The following links can give you everything you want to know and more.
          As you read, remember; the Dino Babies project is a series of NFTs
          and Ziggy is our genesis NFT, we are forming a community wallet with
          the goal of funding an animated series and growing a community, and we
          are incredibly excited for you to join us in the process
        </FaqText>
        <br />
        <FaqText>
          <Link
            onClick={() =>
              window.open("https://ethereum.org/en/nft/", "_blank")
            }
          >
            What is an NFT?
          </Link>{" "}
          - Your guide to everything that is an NFT
        </FaqText>
        <br />
        <FaqText>
          <Link
            onClick={() =>
              window.open(
                "https://www.cnbc.com/2021/10/25/what-are-daos-what-to-know-about-the-next-big-trend-in-crypto.html",
                "_blank"
              )
            }
          >
            What is a DAO
          </Link>{" "}
          - Helping understand our community wallet
        </FaqText>
        <br />
        <FaqText>
          <Link
            onClick={() =>
              window.open(
                "https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask",
                "_blank"
              )
            }
          >
            Guide to Metamask
          </Link>{" "}
          - Our recommended wallet for Web3 interactions
        </FaqText>
      </>
    ),
  },
];

const Faq = () => {
  return (
    <Container id="faq">
      <Title>FAQs</Title>
      <FAQContainer>
        {FAQs.map((faq) => (
          <FaqModule title={faq.title} content={faq.content} key={faq.title} />
        ))}
      </FAQContainer>
    </Container>
  );
};

export default Faq;
