import {
  Container,
  ContentContainer,
  MiddleStrip,
  TextContainer,
  UniqueText,
  Gorzo,
  Number,
} from "./LandingDivider.styles";
import gorzo from "../../images/gorzo.png";

const LandingDivider = () => {
  return (
    <Container>
      <MiddleStrip>
        <ContentContainer>
          <Gorzo src={gorzo} />
          <TextContainer>
            <Number>4,255</Number>
            <UniqueText>Unique Pirates</UniqueText>
          </TextContainer>
        </ContentContainer>
      </MiddleStrip>
    </Container>
  );
};

export default LandingDivider;
