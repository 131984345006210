import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 80vh;
  padding: 2rem 0;
  width: 100%;
  background-color: rgb(239, 140, 86, 0.61);
`;

export const Title = styled.h1`
  width: 75%;
  font-size: 3.5rem;
  font-family: "Jura-Medium";
  margin: 1rem;
  @media ${breakPoints.phoneOnly} {
    font-size: 2.25rem;
    width: 90%;
  }
`;

export const Description = styled.p`
  width: 75%;
  font-size: 1rem;
  margin: 1rem 4rem;
  @media ${breakPoints.phoneOnly} {
    width: 90%;
  }
`;

type ButtonProps = {
  disabled: boolean;
};

export const Button = styled.button<ButtonProps>`
  font-family: "Jura-Medium";
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  background-color: black;
  color: white;
  font-size: 2.25rem;
  border: 3px solid transparent;
  border-radius: 0.5rem;
  margin-top: 2rem;
  z-index: 100;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "default")};
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};

  transition: 0.15s;

  &:hover {
    border: 3px solid #ff488f;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  margin: 1rem;
  font-family: "Jura-Medium";

  @media ${breakPoints.phoneOnly} {
    width: 30%;
    font-size: 0.75rem;
    margin: 0.5rem;
  }
`;

export const CardTitle = styled.h3``;

export const CardNum = styled.p``;
