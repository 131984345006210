import {
  Container,
  Data,
  InfoHeading,
  IntroText,
  TopBanner,
} from "./HistorySection.styles";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import { HISTORY_ENDPOINT } from "../../../../constants";

import axios from "axios";

const HistorySection = () => {
  const [loading, setLoading] = useState(false);
  const [raids, setRaids] = useState<any[]>([]);

  const { account, library } = useWeb3React<Web3Provider>();

  useEffect(() => {
    if (library && account) {
      updateSupplies();

      const // poll every 5 minutes for new history numbers
        interval = setInterval(() => {
          updateSupplies();
        }, 300000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, library]);

  const updateSupplies = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${HISTORY_ENDPOINT}?wallet=${account}`, {
        withCredentials: false,
      });
      const raidRes = res.data;
      setRaids(raidRes);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

    const renderResult = (
    target: string,
    status: string,
    amount: number,
    tokenId: number,
    event: string,
    eggs: number,
  ) => {
      if (event && event === "claim") {
          return (
              <p style={{margin: 0}}>
                  Claim successful! You have secured <span style={{color: "yellow"}}>{eggs}</span> eggs
              </p>
          );
      }
      // if you are the target
      if (target === account?.toLowerCase()) {
        if (status === "success") {
          return (
              <p style={{margin: 0}}>
                <span style={{color: "red"}}>{amount}</span> of your eggs were
                looted by Pirate #{tokenId}
              </p>
          );
        } else {
          return (
              <p style={{margin: 0}}>
                Pirate #{tokenId} attempted to steal your eggs!
              </p>
          );
        }
      } else {
        // Otherwise you are the attacker
        if (status === "success") {
          return (
              <p style={{margin: 0}}>
                  Pirate #{tokenId} stole{" "}
                  <span style={{color: "green"}}>{amount}</span> eggs!
              </p>
          );
        } else if (status === "failure") {
            return (
                <p style={{margin: 0}}>
                    Pirate #{tokenId} {" "} <span style={{color: "red"}}>couldn’t find a victim</span>
                </p>
            );
        } else {
            if (status === "EWWW") {
                return (<p style={{margin: 0}}>Pirate #{tokenId} found <span style={{color: "green"}}>{amount}</span> eggs in the trash! </p>);
            }
            if (status === "GOT A JOB") {
                return (<p style={{margin: 0}}>Pirate #{tokenId} worked for <span style={{color: "green"}}>{amount}</span> eggs! </p>);
            }
            if (status === "ALLOWANCE") {
                return (<p style={{margin: 0}}>Pirate #{tokenId} asked mom for <span style={{color: "green"}}>{amount}</span> eggs! </p>);
            }
            if (status === "FLIPPED") {
                return (<p style={{margin: 0}}>Pirate #{tokenId} flipped a sneaker for <span style={{color: "green"}}>{amount}</span> eggs! </p>);
            }
            if (status === "PANHANDLED") {
                return (<p style={{margin: 0}}>Pirate #{tokenId} begged for <span style={{color: "green"}}>{amount}</span> eggs! </p>);
            }
            if (status === "LUCKY") {
                return (<p style={{margin: 0}}>Pirate #{tokenId} won <span style={{color: "green"}}>{amount}</span> eggs in the lottery! </p>);
            }
            if (status === "failed") {
                return (<p style={{margin: 0}}>User {target} <span style={{color: "red"}}>escaped</span> Pirate #{tokenId}! </p>);
            }
        }
      }
    }

  return (
    <Container>
      <TopBanner />
      <IntroText>
        DinoBabies (R) Ziggy OS
        <br />
        (C) Copyright Ziggy Corp. 2021-2022
        <br />
        <br />
        {"D:/USER/DINOBABIES/RECRUIT~1/DEL>"}
      </IntroText>
      <InfoHeading>
        {loading && "..."}
        {raids.map((data, i) => (
          <Data>
            {}
            {data.event === "claim" &&
              `> [ CLAIMED ] - ${new Date(data.missionTime).toLocaleString()}`}
            {data.target && data.target === account!.toLowerCase() &&
            `> [ ATTACKED ] - ${new Date(data.missionTime).toLocaleString()}`}
            {data.target && data.target !== account!.toLowerCase() && (data.raidStatus === "failure" || data.raidStatus === "failed" || data.raidStatus === "success") &&
            `> [ RAID ${data.raidStatus === "success" ? "SUCCESS" : "FAILED"} ] - ${new Date(data.missionTime).toLocaleString()}`}
            {data.target && data.target !== account!.toLowerCase() && data.raidStatus !== "failure" && data.raidStatus !== "failed" && data.raidStatus !== "success" &&
            `> [ ${data.raidStatus.toUpperCase()} ] - ${new Date(data.missionTime).toLocaleString()}`}
            <br />
            {"    "}
            {/* depending on who the target wallet is, change the style of the button */}
            {renderResult(
                data.target,
                data.raidStatus,
                data.eggsStolen,
                data.tokenId,
                data.event,
                data.eggs,
            )}
          </Data>
        ))}
      </InfoHeading>
    </Container>
  );
};

export default HistorySection;
