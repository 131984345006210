import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffcb00;
  border-bottom: 1rem black solid;
  padding-bottom: 6rem;
  overflow-x: hidden;
  overflow-y: hidden;
  @media ${breakPoints.phoneOnly} {
    padding-bottom: 2rem;
  }
`;

export const Title = styled.div`
  width: 75%;
  text-align: left;
  font-size: 4.5rem;
  font-family: "Jura-Medium";
  margin: 1rem;

  @media ${breakPoints.phoneOnly} {
    font-size: 2.25rem;
    width: 90%;
  }
`;

export const RoadMapContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${breakPoints.tabletPortraitUp} {
    width: 90%;
  }
`;

export const ContentText = styled.p`
  padding: 0;
  margin: 0;
  font-family: "Roboto-Regular";
  font-size: 0.875rem;
`;

export const ArtGuy = styled.img`
  position: absolute;
  bottom: -3rem;
  right: -1rem;
  width: 38rem;

  @media ${breakPoints.tabletPortraitUp} {
    display: none;
  }
`;
