import styled from "styled-components";
import breakPoints from "../../../../../constants/breakpoints";

type ContainerProps = {
  selected: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: ${(props) =>
    props.selected ? "4px #F14376 solid" : "4px transparent solid"};
  width: 10.75rem;
  height: 16.75rem;
  cursor: pointer;
  margin-left: 2rem;
  margin: 1rem;

  @media ${breakPoints.phoneOnly} {
    margin-left: 0;
    margin-bottom: 2rem;
  }
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 10.75rem;
  border-radius: 0.75rem 0.75rem 0 0;
`;

export const ItemDescriptionContainer = styled.div`
  font-family: "Jura-Medium";
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  width: 90%;
  height: 5rem;
  padding: 0.5rem 5%;
  border-radius: 0 0 0.75rem 0.75rem;
  background-color: white;
`;

export const ItemName = styled.p`
  font-weight: 700;
  margin: 0;
`;

export const ItemDescription = styled.p`
  margin: 0;
`;
