import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  height: 17rem;
  background-color: black;
  @media ${breakPoints.tabletPortraitUp} {
    height: 17.25rem;
  }
  @media ${breakPoints.phoneOnly} {
    height: 6.625rem;
  }
`;

export const MiddleStrip = styled.div`
  position: relative;
  width: 100%;
  height: 13.1875rem;
  background-color: #ff488f;
  margin-bottom: 1rem;
  @media ${breakPoints.tabletPortraitUp} {
    height: 13.25rem;
    margin-bottom: 1.5rem;
  }
  @media ${breakPoints.phoneOnly} {
    height: 5.125rem;
    margin-bottom: 0.5rem;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

export const Gorzo = styled.img`
  width: 30.5rem;
  height: auto;
  @media ${breakPoints.tabletPortraitUp} {
    width: 20rem;
  }
  @media ${breakPoints.phoneOnly} {
    width: 12rem;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 13.1875rem;
  font-family: "Jura-Medium";
  @media ${breakPoints.phoneOnly} {
    height: 5.125rem;
  }
`;

export const Number = styled.h2`
  font-size: 6rem;
  margin: 0;
  @media ${breakPoints.tabletPortraitUp} {
    font-size: 4rem;
  }
  @media ${breakPoints.phoneOnly} {
    font-size: 2rem;
  }
`;

export const UniqueText = styled.p`
  font-size: 3rem;
  margin: 0;
  @media ${breakPoints.tabletPortraitUp} {
    font-size: 1.5rem;
  }
  @media ${breakPoints.phoneOnly} {
    font-size: 1.2rem;
  }
`;
