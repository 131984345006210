import styled from "styled-components";
import breakPoints from "../../../../constants/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

type AccordionButtonProps = {
  opened: boolean;
};

export const AccordionButton = styled.div<AccordionButtonProps>`
  transition: transform 0.6s ease;
  transform: ${(props) => (props.opened ? "rotate(0deg)" : "rotate(-90deg)")};
`;

export const Title = styled.div`
  font-size: 2rem;
  font-family: "Jura-Medium";
  margin: 0.5rem 0;
  @media ${breakPoints.tabletPortraitUp} {
    font-size: 1.5rem;
    padding-right: 2rem;
  }
  @media ${breakPoints.phoneOnly} {
    font-size: 1.125rem;
    padding-right: 1rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 1rem 0;

  @media ${breakPoints.tabletPortraitUp} {
    font-size: 0.875rem;
  }
  @media ${breakPoints.phoneOnly} {
    font-size: 0.875rem;
  }
`;
