import {
  Container,
  Line,
  NumContainer,
  SeasonNum,
  SeasonTitle,
  TextContainer,
  PaintOver
} from "./Season.styles";

import paintOver from "../../../../images/paintOver.png";

const Season = ({ num, title, content, complete }: any) => {
  return (
    <Container>
      <NumContainer>
        <SeasonNum>{num}</SeasonNum>
        <Line show={num !== 4} num={num} />
      </NumContainer>
      <TextContainer>
        <SeasonTitle>{title}</SeasonTitle>
        {content}
      </TextContainer>
      {complete && <PaintOver src={paintOver} />}
    </Container>
  );
};

export default Season;
