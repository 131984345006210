import axios from "axios";
import {ORDER_ENDPOINT} from "../../constants";
import {
    Content,
    Container,
    Title,
    ShopContainer,
    BalanceImage,
    Header,
    Image,
    Item,
    ItemContent,
    Name, Price, Stock, Message, OrderField
} from "./Shop.styles";
import egg from "../../images/shop/egg.png";
import {useEffect, useState} from "react";
import {useWeb3React} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import Modal from "../Modal/Modal";
import {useModal} from "../Modal/useModal";


type OrdersProps = {
    products: Map<number, any>;
}

const orderId = "a3AB90e347bfbbda0b61ca84f0d3fea8067b21114d228b916";

const Orders = ({
      products,
    }: OrdersProps) => {
    const [orders, setOrders] = useState<any[]>([]);
    const [error, setError] = useState<string>("");
    const { account, library } = useWeb3React<Web3Provider>();
    const { openModal, toggleModal } = useModal();

    const fetchOrders = async () => {
        try {
            const { data } = await axios.get(`${ORDER_ENDPOINT}s/${account}?id=${orderId}`);
            setOrders(data);
        } catch (err: any) {
            setError(err.message);
            toggleModal();
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    return (
        <ShopContainer>
            {!account && (
                <Message>
                    You have to connect your wallet before retrieving order history
                </Message>
            )}
        <Container>
            <Content>
                {orders.map((order, index) => {
                    if (!products.get(Number(order.productId))) {
                        return null;
                    }
                    // Skip pending orders older than a day, these are prolly failed
                    let d = new Date();
                    d.setTime(d.getTime() - 86400000);
                    if (order.orderStatus === "pending" && order.orderTime < d.getTime()) {
                        return null;
                    }
                    return (
                        <Item key={order.productId}>
                            <ItemContent>
                                <Name>{products.get(order.productId).productName}</Name>
                                <Price>
                                    <BalanceImage src={egg} width={38} height={38} />
                                </Price>
                                <Stock><OrderField>Date</OrderField>: {new Date(order.orderTime).toString()}</Stock>
                                <Stock><OrderField>Status</OrderField>: {order.orderStatus}</Stock>
                                <Stock><OrderField>Quantity</OrderField>: {order.qty}</Stock>
                                {order.orderStatus !== "raffle" ? (
                                    <Stock><OrderField>Transaction</OrderField>: {order.tx}</Stock>
                                ) : null}
                                {"discountCodes" in order ? (
                                    <Stock><OrderField>Discount Codes</OrderField>:<br/>
                                    {order.discountCodes.map(function(code: any, i: number){
                                        return <li>{code}</li>;
                                    })}
                                    </Stock>
                                ) : null}
                            </ItemContent>
                        </Item>
                    );
                })}
            </Content>
        </Container>
        <Modal
            message={error}
            openModal={openModal}
            toggleModal={toggleModal}
        />
        </ShopContainer>
    );
};

export default Orders;