import styled from "styled-components";

type ContainerProps = {
  isOpen: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100vw")};
  overflow: hidden;
  background-color: black;
  color: white;
  margin: 0;
  z-index: 99;
  transition: left 0.3s ease-out;
`;

export const XContainer = styled.div`
  width: 100%;
  color: white;
  margin: 2rem;
`;

export const ListContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Jura-Medium";
  margin-top: 2rem;
`;

export const List = styled.li`
  margin: 1rem 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  margin: 0 auto;
`;

export const ZiggyButt = styled.img`
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 20rem;
`;

export const Anchor = styled.a`
  text-decoration: none;
  color: white;
`;
