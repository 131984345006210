import { useState } from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsMedium } from "react-icons/bs";
import { useLocation } from "react-router-dom";

import {
  Anchor,
  Container,
  HamburgerMenuContainer,
  LeftContainer,
  Link,
  LinkContainer,
  Logo,
  OpenSea,
  RightContainer,
  SocialLink,
  SocialsContainer,
  StyledRouterLink,
  WalletButton,
} from "./Navbar.styles";
import MobileMenu from "../MobileMenu/MobileMenu";

import logo from "../../images/logo.png";

const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
const truncateEthAddress = function (address: string) {
  var match = address.match(truncateRegex);
  if (!match) return address;
  return match[1] + "\u2026" + match[2];
};

const Navbar = ({ onClick, walletAddress }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  return (
    <>
      <Container>
        <LeftContainer>
          <HamburgerMenuContainer>
            <GiHamburgerMenu size={24} onClick={() => setIsOpen(true)} />
          </HamburgerMenuContainer>
          <LinkContainer>
            <Link>
              <StyledRouterLink to="/">
                <Logo src={logo} />
              </StyledRouterLink>
            </Link>
            <Link>
              <StyledRouterLink to="/mint">Mint</StyledRouterLink>
            </Link>
            <Link>
              <StyledRouterLink to="/shop">Shop</StyledRouterLink>
            </Link>
            <Link>
              <StyledRouterLink to="/eggs">Eggs and Staking</StyledRouterLink>
            </Link>
            <Link>
              {location.pathname !== "/" ? (
                <StyledRouterLink to="/#about">About</StyledRouterLink>
              ) : (
                <Anchor href="#about">About</Anchor>
              )}
            </Link>
            <Link>
              <StyledRouterLink to="/faq">FAQ</StyledRouterLink>
            </Link>
            <Link>
              {location.pathname !== "/" ? (
                <StyledRouterLink to="/#roadmap">Roadmap</StyledRouterLink>
              ) : (
                <Anchor href="#roadmap">Roadmap</Anchor>
              )}
            </Link>
            <Link>
              {location.pathname !== "/" ? (
                <StyledRouterLink to="/#team">Team</StyledRouterLink>
              ) : (
                <Anchor href="#team">Team</Anchor>
              )}
            </Link>
            <Link>
              <StyledRouterLink to="/verify">Verify</StyledRouterLink>
            </Link>
          </LinkContainer>
        </LeftContainer>
        <RightContainer>
          <SocialsContainer>
            <SocialLink>
              <FaDiscord
                size={24}
                onClick={() =>
                  window.open(
                    "https://discord.com/channels/903933648250740816/903947332712812565/912491075506155561",
                    "_blank"
                  )
                }
              />
            </SocialLink>
            <SocialLink>
              <FaTwitter
                size={24}
                onClick={() =>
                  window.open("https://twitter.com/dinobabiesNFT", "_blank")
                }
              />
            </SocialLink>
            <SocialLink>
              <OpenSea
                onClick={() =>
                  window.open(
                    "https://opensea.io/collection/dinobabies",
                    "_blank"
                  )
                }
              />
            </SocialLink>
            <SocialLink>
              <BsMedium
                size={24}
                onClick={() =>
                  window.open("https://medium.com/@dinobabies", "_blank")
                }
              />
            </SocialLink>
          </SocialsContainer>
          <WalletButton onClick={() => onClick(true)}>
            {walletAddress ? truncateEthAddress(walletAddress) : "CONNECT"}
          </WalletButton>
        </RightContainer>
      </Container>
      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default Navbar;
