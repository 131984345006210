import styled from "styled-components";
import breakPoints from "../../constants/breakpoints";
import { Link as RouterLink } from "react-router-dom";

export const Container = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4rem;

  background: #000000;
  color: white;
  z-index: 1000;
  font-family: "Jura-Medium";
`;

export const LinkContainer = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  overflow: hidden;
  @media ${breakPoints.tabletPortraitUp} {
    display: none;
  }
  @media ${breakPoints.phoneOnly} {
    display: none;
  }
`;

export const Link = styled.li`
  padding: 0 1rem;
  cursor: pointer;
  transition: 0.2s;
  color: white;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HamburgerMenuContainer = styled.div`
  display: none;
  @media ${breakPoints.tabletPortraitUp} {
    display: block;
    width: 4rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direciton: row;
  margin-right: 2rem;
`;

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  @media ${breakPoints.phoneOnly} {
    display: flex;
  }
`;

export const SocialLink = styled.div`
  padding: 0 1rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }

  @media ${breakPoints.phoneOnly} {
    padding: 0 0.3rem;
  }
`;

export const WalletButton = styled.div`
  border: 3px #ff488f solid;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.9;
    background-color: #ff488f;
  }
  @media ${breakPoints.phoneOnly} {
    display: block;
  }
`;

export const OpenSea = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("images/opensea-logo.png");
  background-position: left center;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const Hamburger = styled.div``;

export const Anchor = styled.a`
  text-decoration: none;
  color: white;
`;

export const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
  color: white;
`;

export const Logo = styled.img`
  width: 3rem;
`;
